import { Component } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './cssLoader.component.html',
  styleUrls: ['./cssLoader.component.scss']
})
export class cssLoaderComponent {
  constructor(){
  }

}
