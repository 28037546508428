export class MinValidation{
  parent: any
  constructor(parent) { this.parent = parent }
    validate(value: any, key: string, data?: any) {

        if (!data.validation?.min) return

        let type = data.type;
        let number = data.validation?.min;
        if (type == "string") {
          if (value && value?.length < number) {
            this.parent.ErrorValidation.setError(key, `This field minimum length is ${number}`);
          }
        } else if (type == "number") {
          if (value < number) {
            this.parent.ErrorValidation.setError(key, `This field minimum value is ${number}`);
          }
        }
    }
}