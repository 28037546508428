<!-- Update Modal -->
<ng-template #updateModal let-modal>
    <app-model-data
        *ngIf="serverResp?.dashboardOptions?.ready && serverResp?.dashboardOptions?.edit && serverResp?.system?.type"
        (filterData)="fetchData(serverResp?.dashboardOptions?.page, $event, modal)" [options]="serverResp"
        [modal]="modal"></app-model-data>
</ng-template>
<!-- [oldData]="serverResp?.dashboardOptions?.oldFilterData" -->
<!-- [updateId]="this.serverResp?.dashboardOptions?.updateId" -->
<!-- [modal]="modal" -->
<!-- // endPoint: serverResp?.dashboardOptions?.endPoint,
// items: serverResp?.dashboardOptions?.updateModel.view[0],
// viewOptions: serverResp?.dashboardOptions?.updateModel,
// postType: serverResp?.dashboardOptions?.updateModel.postType -->

<div>
    <table class="table table-hover dataTable" *ngIf="serverResp?.dashboardOptions?.dataLoading === false" #dataTable>
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col" *ngFor="let heading of serverResp?.dashboardOptions?.formattedHeadersView">{{heading |
                    formatViewName | genericTranslate |
                    async}}
                </th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody *ngIf="serverResp?.data?.length > 0">

            <tr *ngFor="let data of serverResp?.data; let i = index"
                [ngClass]="{'cursor': serverResp?.dashboardOptions?.edit || serverResp?.dashboardOptions?.clickUrl}"
                (click)="navigate(data['id'], $event, updateModal)">

                <th scope="row">{{i+1}}</th>

                <td *ngFor="let heading of serverResp?.dashboardOptions?.formattedHeaders" [ngClass]="{
                        'cap': heading.indexOf('name') > -1,
                        'stopNav': serverResp?.dashboardOptions?.headers[heading] == 'stringNoNav'
                    }">

                    <app-data-table-string #stopNavigation class="stopNavigation" [serverResp]="serverResp"
                        [data]="data" [heading]="heading" [i]="i"></app-data-table-string>

                    <app-data-table-hotString #stopNavigation class="stopNavigation" [serverResp]="serverResp"
                        [data]="data" [heading]="heading" [i]="i"></app-data-table-hotString>

                    <app-data-table-file #stopNavigation class="stopNavigation" [serverResp]="serverResp" [data]="data"
                        [heading]="heading"></app-data-table-file>

                    <app-data-table-image #stopNavigation class="stopNavigation" [serverResp]="serverResp" [data]="data"
                        [heading]="heading"></app-data-table-image>

                    <app-data-table-status #stopNavigation [serverResp]="serverResp" [data]="data"
                        [heading]="heading"></app-data-table-status>

                    <app-data-router-link #stopNavigation [serverResp]="serverResp" [data]="data"
                        [heading]="heading"></app-data-router-link>

                </td>

                <td #stopNavigation>
                    <app-data-table-actions [serverResp]="serverResp" [data]="data" [heading]="heading"
                        (edit)="navigate($event, undefined, updateModal, true)"></app-data-table-actions>
                </td>

            </tr>
        </tbody>
        <tbody *ngIf="!serverResp?.data || serverResp?.data?.length == 0">
            <tr>
                <td colspan="60" style="text-align: center;">{{'No Data Found' | genericTranslate | async}}</td>
            </tr>
        </tbody>
    </table>
</div>