<div class="container-fluid" *ngIf="loaded" style="padding-top:20px">
  <div class="" style="justify-content: center;">
    <div class="welcome">
      <app-welcome class="basis"></app-welcome>
      <app-followers-growth class="basis" [data]="serverData.visitors"></app-followers-growth>
    </div>
    <div class="charts-container">
      <app-product-status-chart-box [data]="c" *ngFor="let c of serverData.counters"></app-product-status-chart-box>
    </div>
    <div class="col-12"><!--col-xxl-4 col-xl-7 col-md-6 col-sm-5 box-col-6 fullMd-->
      <app-recent-orders [data]="serverData.recent"></app-recent-orders>
    </div>
    <div class="col-xxl-4 col-xl-5 col-md-6 col-sm-7 notification box-col-6 fullMd">
    </div>
  </div>

  <div class="container-fluid basic_table listViewMaxWidth">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h3>{{'Pending Approval Proprties' | genericTranslate | async}}</h3>
          </div>
          <div class="table-responsive-removed signal-table">
            <app-generic-table [endPoint]="'dashboard/admin/proprties/pending'"></app-generic-table>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>


<!-- <div class="container-fluid" *ngIf="loaded" style="padding-top:20px">
    <div class="row widget-grid" style="justify-content: center;">
      <div class="col-xxl-4 col-sm-6 box-col-6 fullMd">
        <app-welcome></app-welcome>
      </div>
      <div class="col-xxl-auto col-xl-3 col-sm-6 box-col-6">
        <div class="row">
          <div class="col-xl-12">
            <app-product-status-chart-box [data]="admins"></app-product-status-chart-box>
            <div class="col-xl-12">
            <app-product-status-chart-box [data]="clients"></app-product-status-chart-box>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-auto col-xl-3 col-sm-6 box-col-6">
        <div class="row">
          <div class="col-xl-12">
            <app-product-status-chart-box [data]="resumesSent"></app-product-status-chart-box>
            <div class="col-xl-12">
            <app-product-status-chart-box [data]="duplicatedOrders"></app-product-status-chart-box>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-4 col-xl-7 col-md-6 col-sm-5 box-col-6 fullMd">
        <app-recent-orders [data]="serverData.orders"></app-recent-orders>
      </div>
      <div class="col-xxl-4 col-xl-5 col-md-6 col-sm-7 notification box-col-6 fullMd">
        <app-followers-growth [data]="serverData.orderCounts"></app-followers-growth>
      </div>
    </div>

    <div class="container-fluid basic_table listViewMaxWidth">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3>{{'Latest Audit Orders' | genericTranslate | async}}</h3>
                    </div>
                    <div class="table-responsive-removed signal-table">
                        <app-generic-table [endPoint]="'order/audit'"></app-generic-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  </div> -->