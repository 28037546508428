import { Component } from '@angular/core';
import { HttpDataService } from '../../../ijd-services/httpService.service';
import { UserInterface } from '../../../ijd-interfaces/user.interface';

type UserData = keyof UserInterface;

@Component({
  selector: 'app-list-dashboard',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class dashboardComponent {

  loaded: boolean = false
  constructor(private HttpDataService: HttpDataService){
    this.getData()
  }

  serverData: any
  async getData(){
    let resp = await this.HttpDataService.get("dashboard/")
    if( resp.success ){
      // this.admins.counter = this.admins.counter.toString().padStart(2, "0")
      // this.clients.counter = this.clients.counter.toString().padStart(2, "0")
      // this.resumesSent.counter = this.resumesSent.counter.toString().padStart(2, "0")
      // this.duplicatedOrders.counter = this.duplicatedOrders.counter.toString().padStart(2, "0")
      this.serverData = resp
      this.loaded = true
    }
  }

  // public admins = {
  //   icon: "fill-user",
  //   counter: "10,000",
  //   name: "Admins",
  //   font: "success",
  //   pr: "",
  // };

  // public clients = {
  //   icon: "user-visitor",
  //   counter: "10,000",
  //   name: "Clients",
  //   font: "warning",
  //   pr: "",
  // };

  // public resumesSent = {
  //   icon: "new-order",
  //   counter: "4,200",
  //   // name: "Resumes Sent",
  //   name: "Manual Orders",
  //   font: "primary",
  //   pr: "",
  // };

  // public duplicatedOrders = {
  //   icon: "rate",
  //   counter: "5700",
  //   // name: "Duplicated Orders",
  //   name: "Salla Orders",
  //   font: "secondary",
  //   pr: "",
  // };

}
