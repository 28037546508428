export class EnumValidation {
    parent: any
    constructor(parent) { this.parent = parent }
    validate(value: any, key: string, data?: any) {

        if( data.type != "enum" ) return

        let enumData = data.validation?.enum?.split(",");
        if (enumData.indexOf(value) == -1) {
            this.parent.ErrorValidation.setError(
                key,
                `This field value should be one of ${enumData.join(",")}`
            );
        }
    }
}