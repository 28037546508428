<div *ngIf="loaded" class="card height-equal">
  <div class="card-header card-no-border">
    <div class="header-top">
      <h5>{{serverData.name | genericTranslate | async}}</h5>
      <div class="card-header-right-icon">
      </div>
    </div>
  </div>
  <div class="card-body pt-0">
    <div class="row recent-wrapper">
      <div class="col-xl-8">
        <div class="recent-chart">
          <div id="recentchart">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [plotOptions]="chartOptions.plotOptions"
              [labels]="chartOptions.labels"
            ></apx-chart>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <ul class="order-content">
          <li *ngFor="let d of serverData.viewData">
            <span class="recent-circle bg-primary"> </span>
            <div>
              <span class="f-light f-w-500 cap">{{d.label | genericTranslate | async}} {{'orders' | genericTranslate | async}} </span>
              <h4 class="mt-1 mb-0">{{d.series | genericTranslate | async}}<span class="f-light f-14 f-w-400 ms-1"> </span></h4>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
