<ng-container *ngIf="serverResp?.dashboardOptions?.headers[heading] == 'string'">
    {{data[heading]}}
</ng-container>

<ng-container *ngIf="serverResp?.dashboardOptions?.headers[heading] == 'stringNoNav'" >
    <span class="stopNav">{{data[heading]}}</span>
</ng-container>

<ng-container *ngIf="serverResp?.dashboardOptions?.headers[heading] == 'date'">
    {{convertToHumanDate(data[heading])}}
</ng-container>