import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, NgModule, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { HttpDataService } from '../../ijd-services/httpService.service';

@Component({
  selector: "app-model-data-create",
  templateUrl: "./modelDataCreate.component.html",
  styleUrls: ["./modelDataCreate.component.scss"],
})
export class ModelDataCreateComponent implements OnInit {

  constructor(private route: ActivatedRoute, private HttpDataService: HttpDataService) { }
  endPoint: string
  actions: any
  navigateTo: string
  type: string = "create"

  routeData: any 
  async ngOnInit() {
    if( Object.keys(this.route.snapshot.queryParams).length > 0 ){
      this.routeData = {
        data: {
          additionalData: JSON.parse(this.route.snapshot.queryParams.data)
        }
      }
    }
    if( Object.keys(this.route.snapshot.data).length > 0 ){
      this.routeData = this.route.snapshot
    }

    const additionalData = this.routeData.data.additionalData;
    this.endPoint = this.routeData.data.additionalData.endPoint


    this.navigateTo = this.routeData.data.additionalData.navigateTo ?? "back"
    let actions = await this.HttpDataService.get(this.endPoint)
    // this.actions = actions.dashboardOptions
    this.actions = actions
    this.actions.system = {}
    this.actions.system.endPoint = this.endPoint
    this.actions.system.type = this.type
    this.actions.system.navigateTo = this.navigateTo
    this.actions.system.targetModel = "createModel"

    if( this.endPoint == "auth/user/profile" ) this.type = "update"
    this.actions.system.type = this.type
    this.actions.system.targetModel = "updateModel"

    if( this.routeData.data.additionalData?.type ){
      this.type = this.routeData.data.additionalData.type
    }
  }

  
}
