import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpDataService } from '../../../../ijd-services/httpService.service';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import { PdfService } from 'src/app/components/ijd-services/pdf.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: "app-export-table",
    templateUrl: "./export.component.html",
    styleUrls: ["./export.component.scss"],
    encapsulation: ViewEncapsulation.None,
})

export class ExportComponent implements OnInit, OnChanges, AfterViewInit {

    @Input("serverResp") serverResp: any
    @Output("fetchData") fetchData: EventEmitter<any> = new EventEmitter()

    constructor(protected HttpDataService: HttpDataService, private modalService: NgbModal, private PdfService: PdfService, private elRef: ElementRef, private ActivatedRoute: ActivatedRoute) { }
    ngOnInit() {
        this.serverResp.dashboardOptions.isDownloadingPdf = false
        this.serverResp.dashboardOptions.isDownloadingExcel = false
    }

    ngAfterViewInit() {

    }

    ngOnChanges(changes: SimpleChanges) { }
    updateInput(data: any){
        try{
            this.serverResp.dashboardOptions.ready = false
            setTimeout(() => {
                this.serverResp = data
                this.serverResp.dashboardOptions.isDownloadingPdf = false
                this.serverResp.dashboardOptions.isDownloadingExcel = false
                this.serverResp.dashboardOptions.ready = true
            }, 100);
        }catch(e){}
    }
    /**
     * Send To Parent Filter Attributes
     */
    savedEvent: any
    savedModel: any
    sendFetch(page: number, event: Event, modal: any) {
        this.savedEvent = event
        this.savedModel = modal
        this.fetchData.emit({
            page,
            event,
            modal
        })
    }

    /**
     * Open Filter Modal
     */
    filterModalOpened: boolean = false
    openFilterModal(content: any) {
        this.filterModalOpened = true
        this.serverResp.system = {}
        this.serverResp.system.type = "filter"
        this.serverResp.system.targetModel = "filterModel"
        this.modalService
            .open(content, { centered: true })
            .result.then(async (result) => {
                this.filterModalOpened = false
                this.modalService.dismissAll();
            });
    }

    /**
     * Convert user filter input to url query object 
     * 
     * eg. {name: "mohamed"} output: name=mohamed
     */
    objectToQueryString(obj: any) {
        let formattedObj = {};
        Object.keys(obj).forEach((key) => {
            if (Array.isArray(obj[key])) {
                if (obj[key].length > 0 && obj[key][0].value) {
                    //this will be ngmodel
                    formattedObj[key] = obj[key].map((x) => x.value).join(",");
                } else if (obj[key].length > 0 && obj[key][0].label) {
                    formattedObj[key] = obj[key].map((x) => x.label).join(",");
                } else {
                    //this will be normal array
                    formattedObj[key] = obj[key].join(",");
                }
            } else {
                formattedObj[key] = obj[key];
            }
        });

        return Object.keys(formattedObj)
            .map(
                (key) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(formattedObj[key])}`
            )
            .join("&");
    }

    /**
     * Returns Data Table From Parent
     */
    getDataTable() {
        return this.elRef.nativeElement.parentElement.querySelector(".dataTable")
    }

    /**
     * Toggle PDF/Excel Spinners
     */
    toggleSpinner(type: string) {
        type == "pdf" ? this.serverResp.dashboardOptions.isDownloadingPdf = !this.serverResp.dashboardOptions.isDownloadingPdf : ""
        type == "excel" ? this.serverResp.dashboardOptions.isDownloadingExcel = !this.serverResp.dashboardOptions.isDownloadingExcel : ""
        // type == "excel" ? !this.serverResp.dashboardOptions.isDownloadingExcel ? this.serverResp.dashboardOptions.isDownloadingExcel = true : this.serverResp.dashboardOptions.isDownloadingExcel = false : ""
    }

    /**
     * Make Downloaded File Name Todays Date + Route Name
     */
    getFileName() {
        return `${this.serverResp?.dashboardOptions.endPoint.replace(
            "/",
            ""
        )}-${this.getCurrentHumanDate()}`
    }

    /**
     * Returns JS Data As Readable Human Date
     */
    getCurrentHumanDate() {
        return this.convertToHumanDate(new Date())
    }

    /**
     * Convert Data Table From Parent To PDF
     */
    async generatePdf() {

        this.toggleSpinner("pdf")

        await this.PdfService.generatePdf(
            this.getDataTable(),
            this.getCurrentHumanDate(),
            this.getFileName(),
            this.serverResp?.dashboardOptions?.reportName
        );

        this.toggleSpinner("pdf")

    }

    /**
     * Generate PDF From Server
     */
    async generatePdfServerSide() {

        this.toggleSpinner("pdf")

        if (this.serverResp?.dashboardOptions?.tableFiltered) {
            //export filter
            let resp = await this.HttpDataService.download(
                //exportFiltered
                `${this.serverResp?.dashboardOptions.endPoint}/export?export-type=pdf&${this.objectToQueryString(this.serverResp.dashboardOptions.oldFilterData)}`.replace("//", "/"),
                {
                    auth: true,
                    link: true,
                    contentType: "application/pdf",
                    fileName: `${this.serverResp?.dashboardOptions.reportName}-${this.convertToHumanDate(new Date())}`,
                }
            );
        } else {
            //export all
            let resp = await this.HttpDataService.download(
                //exportAll
                `${this.serverResp?.dashboardOptions.endPoint}/export?export-type=pdf`.replace("//", "/"),
                {
                    auth: true,
                    link: true,
                    contentType: "application/pdf",
                    fileName: `${this.serverResp?.dashboardOptions.reportName}-${this.convertToHumanDate(new Date())}`,
                }
            );
        }

        this.toggleSpinner("pdf")

    }

    async downloadPDF(content: any) {

        if (!this.serverResp?.dashboardOptions?.pdf || this.serverResp?.dashboardOptions?.isDownloadingPdf) return

        this.modalService.open(content, { centered: true }).result.then(async (result) => {

            if (result == "exportThisPage") this.generatePdf()

            if (result == "exportAllData") this.generatePdfServerSide()

        });

    }

    /**
     * Convert Data Table From Parent To EXCEL
     */
    async generateExcel() {

        this.toggleSpinner("excel")

        await this.PdfService.generateُExcel(
            this.getDataTable(),
            this.getCurrentHumanDate()
        );

        this.toggleSpinner("excel")

    }

    /**
     * Generate EXCEL From Server
     */
    async generateExcelServerSide() {

        this.toggleSpinner("excel")

        if (this.serverResp?.dashboardOptions?.tableFiltered) {
            //export filtered data
            let resp = await this.HttpDataService.download(
                //exportAllExcelFiltered
                `${this.serverResp?.dashboardOptions.endPoint}/export?export-type=excel&${this.objectToQueryString(this.serverResp.dashboardOptions.oldFilterData)}`.replace("//", "/"),
                {
                    auth: true,
                    link: true,
                    contentType: "application/xlsx",
                    fileName: `${this.serverResp?.dashboardOptions.reportName}-${this.convertToHumanDate(new Date())}`,
                }
            );
        } else {
            //export all data
            let resp = await this.HttpDataService.download(
                //exportAllExcel
                `${this.serverResp?.dashboardOptions.endPoint}/export?export-type=excel`.replace("//", "/"),
                {
                    auth: true,
                    link: true,
                    contentType: "application/xlsx",
                    fileName: `${this.serverResp?.dashboardOptions.reportName}-${this.convertToHumanDate(new Date())}`,
                }
            );
        }

        this.toggleSpinner("excel")

    }

    async downloadExcel(content: any) {

        if (!this.serverResp?.dashboardOptions?.pdf || this.serverResp.dashboardOptions.isDownloadingExcel) return

        this.modalService.open(content, { centered: true }).result.then(async (result) => {

            if (result == "exportThisPage") this.generateExcel()

            if (result == "exportAllData") this.generateExcelServerSide()

        });

    }

    convertToHumanDate(dateString: any) {
        try {
            const date = new Date(dateString);
            const options = {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: false,
            };
            //@ts-ignore
            return date.toLocaleString("en-US", options);
        } catch (e) {
            return dateString;
        }
    }

    stringifyObject() {
        let object = {
            endPoint: this.serverResp.dashboardOptions.endPoint,
            navigateTo: this.serverResp.dashboardOptions.navigateTo,
            type: 'create'
        }
        return object
    }
}