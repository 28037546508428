import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { _GenericTranslatePipe } from '../../ijd-pipes/translate.pipe';
import { HttpDataService } from "./httpService.service";

@Injectable({
  providedIn: "root",
})
export class PipedToasterService {

  langData: any = {}

  constructor(
    private toaster: ToastrService,
    private HttpDataService: HttpDataService
  ) {}

  async success(string: string){
    this.toaster.success(await await this.getTranslation(string))
  }

  async error(string: string){
    this.toaster.error(await await this.getTranslation(string))
  }

  async getTranslation(value: string){
    let lng = await this.getLangJson()
    if( lng[value] ){
      return lng[value]
    }else{
      lng[value] = value
      this.sendNewWord(value)
      return value
    }
  }

  async sendNewWord(word: string){
    let resp = await this.HttpDataService.post("lang/lng/new",{
      value: word
    })
  }

  async getLangJson(){
    if( Object.keys(this.langData).length == 0 ){
      let resp = await this.HttpDataService.get(`lang/lng/${this.getLangCode()}`)
      this.langData = resp
      return resp
    }else{
      return this.langData
    }
  }

  getLangCode(){
    let code = localStorage.getItem("lng")
    if( !code ){
      return 1
    }else{
      return JSON.parse(code).id
    }
  }
}
