import { ImportAllValidations } from "../validations/importValidations";

export class Filter {
    parent: ImportAllValidations
    constructor(parent) { this.parent = parent }

    filter() {

        if (this.parent.data.system.type != "filter") {
            this.parent.runValidation();
            // this.filterData.emit(this.parent.ClearFilterObject.clear())
            return {
                emit: "filterData",
                value: this.parent.ClearFilterObject.clear()
            }
        }
        if (!this.parent.data.system.validationError) {
            if (this.parent.data.system.obj["startDate"] != "" && this.parent.data.system.obj["endDate"] == "") {
                // this.toastr.error("Please choose end date first")
                return {
                    toaster: "error",
                    value: "Please choose end date first"
                }
            }

            if (this.parent.data.system.obj["endDate"] != "" && this.parent.data.system.obj["startDate"] == "") {
                // this.toastr.error("Please choose start date first")
                return {
                    toaster: "error",
                    value: "Please choose end start first"
                }
            }
            return {
                emit: "filterData",
                value: this.parent.ClearFilterObject.clear()
            }
            // this.filterData.emit(this.parent.ClearFilterObject.clear())
        }

    }
}