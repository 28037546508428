<!-- Delete Modal -->
<ng-template #deleteModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'Are you sure you want to delete ?' | genericTranslate |
            async}}
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Delete')"
            style="background-color: red;border: unset; color: white">{{'Delete !' | genericTranslate | async}}</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">{{'Back' | genericTranslate |
            async}}</button>
    </div>
</ng-template>

<div class="actionsDiv stopNav">
    <div class="media" *ngIf="serverResp?.dashboardOptions?.disable && checkPerm('disabled')">
        <div class="media-body text-end icon-state switch-sm">
            <label class="switch">
                <input type="checkbox" [checked]="data['status']=='active'"><span (click)="disable(data['id'])"
                    class="switch-state"></span>
            </label>
        </div>
    </div>
    <span class="material-symbols-outlined editIcon" *ngIf="serverResp?.dashboardOptions?.edit && checkPerm('update')"
        (click)="editAction(data['id'])">
        edit
    </span>
    <!-- <span class="material-symbols-outlined editIcon" *ngIf="serverResp?.dashboardOptions?.edit && checkPerm('update')"
        (click)="DataTableSharedService.navigate(data['id'], $event, updateModal, true)">
        edit
    </span> -->
    <span class="material-symbols-outlined deleteIcon"
        *ngIf="serverResp?.dashboardOptions?.delete && checkPerm('deleted')"
        (click)="deleteRecord(data['id'], deleteModal)">
        delete
    </span>
</div>