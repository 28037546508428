import { EventEmitter, Injectable } from "@angular/core";
import { DataTableComponent } from '../../data-table.component';

@Injectable({
    providedIn: "root",
})

export class DataTableSharedService {

    constructor() { }

    navigateEvent: EventEmitter<any> = new EventEmitter()

    navigate(id: number, event: Event, updateModal: any, forceNavigation: boolean = false) {
        this.navigateEvent.emit({ id, event, updateModal, forceNavigation })
    }

}