<div class="charts-container">
  <app-product-status-chart-box [style]="c.inlineStyle" [data]="c"
    *ngFor="let c of serverResp.statstics"></app-product-status-chart-box>
</div>
<!-- Export PDF/Excel + Create Button + Filter Button -->
<app-export-table #appDataTableOptions [serverResp]="serverResp"
  (fetchData)="fetchData($event.page, $event.event, $event.modal)"></app-export-table>

<!-- Data Table -->
<app-data-table [serverResp]="serverResp"
  (fetchData)="fetchData($event.page, $event.filterData, $event.modal)"></app-data-table>

<!-- Show Skeleton Table While Loading -->
<app-skeleton-table
  [ngClass]="{'skeltonTableHidden': serverResp?.dashboardOptions?.dataLoading === false}"></app-skeleton-table>

<!-- Pagination -->
<app-paginated-table [serverResp]="serverResp" (openPageEvent)="fetchData($event)"></app-paginated-table>

<!--Charts-->
<app-ngx-chart [serverResp]="serverResp"></app-ngx-chart>

<!-- Extra Styles -->
<div #extraStyles class="extraStyles"></div>