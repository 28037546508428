export class ErrorValidation {

  parent: any
  constructor(parent) { this.parent = parent }

  setError(key: string, message: string) {
    this.parent.data.system.validationError = true;
    if (this.parent.data.system.validationMessages[key]) {
      this.parent.data.system.validationMessages[key] = `${this.parent.data.system.validationMessages[key]} , ${message}`
    } else {
      this.parent.data.system.validationMessages[key] = message
    }
  }
}
