import { Component, ElementRef, Input, QueryList, ViewChildren } from "@angular/core";
import { HttpDataService } from '../../../../../../ijd-services/httpService.service';

@Component({
    selector: "app-data-table-string",
    templateUrl: "./string.component.html",
    styleUrls: ["./string.component.scss"],
})

export class DataTableStringComponent {

    constructor(protected HttpDataService: HttpDataService) { }

    @Input("serverResp") serverResp: any
    @Input("data") data: any
    @Input("heading") heading: any

    convertToHumanDate(dateString: any) {
        try {
            const date = new Date(dateString);
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true
            };
            //@ts-ignore
            return date.toLocaleString('en-US', options);
        } catch (e) {
            return dateString
        }
    }
}