import { Pipe, PipeTransform } from '@angular/core';
import { HttpDataService } from '../components/ijd-services/httpService.service';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'genericTable',
  pure: false
})
export class GenericTablePipe implements PipeTransform {

  constructor(private HttpDataService: HttpDataService, private sanitizer: DomSanitizer) { }

  transform(value: any, arg1: any): any {
    if (arg1 == "string") {
      return value
    } else if (arg1 == "date") {
      return this.convertToHumanDate(value)
    } else if (arg1 == "image") {
      return ""
    } else if (arg1 == "status") {
      return ""
    } else if (arg1 == "file") {
      return ""
    } else if (arg1.indexOf("FirstComma") > -1) {
      return ""
    } else if (arg1 == "orderStatus") {
      return ""
    } else if (arg1 == "hotString") {
      return ""
    } else if (arg1 == "ckeditor") {
      return ""
    }

    return value;
  }

  convertToHumanDate(dateString: any) {
    try {
      const date = new Date(dateString);
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
      };
      //@ts-ignore
      return date.toLocaleString('en-US', options);
    } catch (e) {
      return dateString
    }
  }

}
