import { Component, ElementRef, Input, QueryList, ViewChildren } from "@angular/core";
import { HttpDataService } from '../../../../../../ijd-services/httpService.service';

@Component({
    selector: "app-data-table-status",
    templateUrl: "./status.component.html",
    styleUrls: ["./status.component.scss"],
})

export class DataTableStatusComponent {

    constructor(protected HttpDataService: HttpDataService) { }

    @Input("serverResp") serverResp: any
    @Input("data") data: any
    @Input("heading") heading: any
}