import { Component, EventEmitter, Input, Output } from "@angular/core";
import { HttpDataService } from '../../../../../../ijd-services/httpService.service';
import { DataTableSharedService } from '../sharedServices/dataTableSharedServices.serivce';
import { PipedToasterService } from "src/app/components/ijd-services/toaster.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-data-table-actions",
    templateUrl: "./actions.component.html",
    styleUrls: ["./actions.component.scss"],
})

export class DataTableActionsComponent {
    constructor(
        protected HttpDataService: HttpDataService,
        protected DataTableSharedService: DataTableSharedService,
        private toastr: PipedToasterService,
        private modalService: NgbModal,
    ) { }

    @Input("serverResp") serverResp: any
    @Input("data") data: any
    @Input("heading") heading: any
    @Output("edit") edit: EventEmitter<any> = new EventEmitter()
    /**
     * Checks If User has permission
     */
    checkPerm(perm: string) {
        return this.HttpDataService.hasPermissionDirect([`${this.serverResp.dashboardOptions.permPerfix}_${perm}`])
    }

    editAction(id: number){
        this.edit.emit(id)
    }

    /**
     * Sends Disable Command To Backend
     */
    async disable(id: number) {
        let action =
            this.serverResp?.data?.find((x) => x.id == id).status == "disabled"
                ? "active"
                : "disabled";

        let resp = await this.HttpDataService.get(
            `${this.serverResp.dashboardOptions.endPoint}/${action}/${id}`.replace("//", "/")
        );

        if (resp.success) {
            this.serverResp.data.find((x) => x.id == id).status = action
            this.toastr.success("Command run successfully .");
        } else {
            this.toastr.error("Error, Please try again later .");
        }
    }

    /**
     * Sends Delete Command To Backend
     */
    async deleteRecord(id: number, content: any) {
        this.modalService
            .open(content, { centered: true })
            .result.then(async (result) => {
                if (result == "Delete") {
                    let resp = await this.HttpDataService.get(
                        `${this.serverResp.dashboardOptions.endPoint}/deleted/${id}`.replace("//", "/")
                    );
                    if (resp.success) {
                        let indexToRemove = this.serverResp?.data?.findIndex((x) => x.id == id)
                        this.serverResp?.data.splice(indexToRemove, 1);
                        this.toastr.success("Command run successfully .");
                    } else {
                        this.toastr.error("Error, Please try again later .");
                    }
                }
            });
    }

}