import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpDataService } from '../../../../../components/ijd-services/httpService.service';

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: string = "assets/images/dashboard/profile.jpg";

  constructor(public router: Router, private HttpDataService: HttpDataService) {
    if (JSON.parse(localStorage.getItem("user"))) {
      this.profileImg = this.HttpDataService.baseUrl + '/uploads?path=' + JSON.parse(localStorage.getItem("user")).image
      this.userName = JSON.parse(localStorage.getItem("user")).name
    } else {
    }
  }

  ngOnInit() {}

  logoutFunc() {
    this.HttpDataService.get("users/logout")
    localStorage.removeItem("user")
    this.router.navigateByUrl('auth/login');
  }

  editProfile(){
    const additionalData = {
      endPoint: "auth/user/profile",
      type: "update"
    }
    this.router.navigate(['profile'],{ queryParams: {data: JSON.stringify(additionalData)}})
  }

}
