import { Pipe, PipeTransform } from '@angular/core';
import { HttpDataService } from '../components/ijd-services/httpService.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LayoutService } from '../shared/services/layout.service';

@Pipe({
  name: 'genericTranslate',
  pure: true
})
export class _GenericTranslatePipe implements PipeTransform {

  langData: any = {}
  public layoutType: string = "ltr";

  constructor(private HttpDataService: HttpDataService, private sanitizer: DomSanitizer, public layout: LayoutService) { }

  async transform(value: any, arg1: any) {
    return await this.getTranslation(value)
  }

  customizeLayoutType(val) {
    this.layoutType = val;
    this.layout.config.settings.layout_type = val;
    if (val == "rtl") {
      document.getElementsByTagName("html")[0].setAttribute("dir", val);
      document.body.className = "rtl";
    } else if (val == "box-layout") {
      document.getElementsByTagName("html")[0].setAttribute("dir", val);
      document.body.className = "box-layout";
    } else {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.body.className = "";
    }
  }


  async getTranslation(value: string) {
    let lng = await this.getLangJson()
    if (lng[value]) {
      return lng[value]
    } else {
      lng[value] = value
      if (localStorage.getItem("updateLng")) { this.sendNewWord(value) }
      return value
    }
  }

  async sendNewWord(word: string) {
    let resp = await this.HttpDataService.post("lang/lng/new", {
      value: word
    })
  }

  async getLangJson() {
    if (
      //Object.keys(this.langData).length == 0
      !document.querySelector("#pipeFix") || (<any>document.querySelector("#pipeFix"))?.value == ""
    ) {
      let resp = await this.HttpDataService.get(`lang/lng/${this.getLangCode()}`, { handleError: false })
      this.langData = resp;
      if (document.querySelector("#pipeFix")) {
        (<any>document.querySelector("#pipeFix")).value = JSON.stringify(resp);
      }
      return resp
    } else {
      return JSON.parse((<any>document.querySelector("#pipeFix")).value)
    }
  }

  getLangCode() {
    let code = localStorage.getItem("lng")
    if (!code) {
      return 1
    } else {
      try {
        return JSON.parse(code).id
      } catch (e) {
        return code
      }
    }
  }
}

