// Import required modules
import { Injectable } from "@angular/core";
import generatePDF from "../../../assets/jspdf/script.js";
import ExportToExcel from "../../../assets/jspdf/excel.js";

@Injectable({
  providedIn: "root",
})
export class PdfService {
  constructor() {}

  generatePdf(table: HTMLElement, title: string, fileName: string, reportName: string) {
    return new Promise(async (resolve) => {
      await generatePDF(title, this.table2Array(table), fileName, reportName);
      resolve(true);
    });
  }

  generateUserSubPdf(data, chartData, mobile, name, text){
    return new Promise(async (resolve) => {
      await generatePDF(`جوال رقم : ${mobile}`, this.table2Array(this.arrayToTable(data)), "", ` المحترم / ${name}`, true, chartData, text);
      resolve(true);
    });
  }

  generateُExcel(table: HTMLElement, fileName: string) {
    return new Promise(async (resolve) => {
      this.removeLastRowOfTable(table)
      await ExportToExcel("xlsx", fileName, document.querySelector("#excelDiv table"));
      resolve(true);
    });
  }

  table2Array(table: HTMLElement) {
    let headers: any = [];
    let data: any = [];
    let getLength = table.querySelectorAll("thead tr th").length;
    let getRowLength =
      table.querySelectorAll("thead tr th").length *
        table.querySelectorAll("tbody tr").length -
      table.querySelectorAll("tbody tr").length;
    //get Headers
    table.querySelectorAll("thead tr th").forEach((head, index) => {
      let header = head as HTMLElement;
      index < getLength - 1 ? headers.push(header.innerText) : "";
    });

    //get Data
    let indexCount = 0;
    let rowCount = 0;
    let arr: any = [];
    table.querySelectorAll("tbody tr th,tbody tr td").forEach((cell) => {
      let tableCell = cell as HTMLElement;
      indexCount < getLength - 1
        ? arr.push(tableCell.innerText)
        : rowCount < getRowLength
        ? ((indexCount = -1), data.push(arr), (arr = []))
        : "";
      indexCount++;
      rowCount++;
    });
    data.push(arr);

    console.log(headers)
    console.log(data)
    return [[...headers], ...data];
  }

  arrayToTable(data) {
    // Create the table element
    var table = document.createElement('table');

    // Create the table header
    var thead = document.createElement('thead');
    var headerRow = thead.insertRow();
    for (var key in data[0]) {
        var th = document.createElement('th');
        th.textContent = key;
        headerRow.appendChild(th);
    }
    table.appendChild(thead);

    // Create the table body
    var tbody = document.createElement('tbody');
    data.forEach(function(item) {
        var row = tbody.insertRow();
        for (var key in item) {
            var cell = row.insertCell();
            cell.textContent = item[key];
        }
    });
    table.appendChild(tbody);

    return table;
}

  removeLastRowOfTable(tableRaw: any) {
    //clone Table First
    document.querySelector("#excelDiv").innerHTML = `<table>${tableRaw.innerHTML}</table>`
    let table: any = document.querySelector("#excelDiv table")
    for (var i = 0; i < table.rows.length; i++) {
      var lastCellIndex = table.rows[i].cells.length - 1;
      var lastCell = table.rows[i].cells[lastCellIndex];
      table.rows[i].deleteCell(lastCellIndex);
    }
  }
}
