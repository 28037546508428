import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ModelDataModule } from "./../components/ijd/modelData/modelData.module";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { DragulaModule } from "ng2-dragula";
import { TranslateModule } from "@ngx-translate/core";
// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { CustomizerComponent } from "./components/customizer/customizer.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
import { SearchComponent } from "./components/header/elements/search/search.component";
import { MegaMenuComponent } from "./components/header/elements/mega-menu/mega-menu.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { BookmarkComponent } from "./components/header/elements/bookmark/bookmark.component";
import { CartComponent } from "./components/header/elements/cart/cart.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";
// Directives
import { DisableKeyPressDirective } from "./directives/disable-key-press.directive";
import { OnlyAlphabetsDirective } from "./directives/only-alphabets.directive";
import { OnlyNumbersDirective } from "./directives/only-numbers.directive";
import { ShowOptionsDirective } from "./directives/show-options.directive";
// Services
import { ChatService } from "./services/chat.service";
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { TableService } from "./services/table.service";
import { NgbdSortableHeader } from "./directives/NgbdSortableHeader";
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from "./components/header/elements/swiper/swiper.component";
import { GenericTableComponent } from "../components/ijd/generic-table/generic-table.component";
import { HttpDataService } from "../components/ijd-services/httpService.service";
import { cssLoaderComponent } from "../components/ijd/cssLoader/cssLoader.component";
import { GenericTablePipe } from "../ijd-pipes/generic-table.pipe";
import { PdfService } from "../components/ijd-services/pdf.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { ModelDataCreateComponent } from "../components/ijd/modelDataCreate/modelDataCreate.component";
import { TranslatePipeModule } from "../ijd-pipes/translate.module";
import { SkeletonTableComponent } from '../components/ijd/generic-table/generic-table-utils/skeleton-loading/skeleton-loading.component';
import { PaginationComponent } from '../components/ijd/generic-table/generic-table-utils/pagination/pagination.component';
import { FormatViewName } from '../components/ijd/generic-table/generic-table-utils/viewNamePipe/viewName.pipe';
import { ExportComponent } from '../components/ijd/generic-table/generic-table-utils/export-pdf-excel-createButton-FilterButton/export.component';
import { DataTableComponent } from '../components/ijd/generic-table/generic-table-utils/dataTable/data-table.component';
import { DataTableActionsComponent } from '../components/ijd/generic-table/generic-table-utils/dataTable/dataTableElements/actions/actions.component';
import { DataTableImageComponent } from '../components/ijd/generic-table/generic-table-utils/dataTable/dataTableElements/image/image.component';
import { DataTableStatusComponent } from '../components/ijd/generic-table/generic-table-utils/dataTable/dataTableElements/status/status.component';
import { DataTableFileComponent } from '../components/ijd/generic-table/generic-table-utils/dataTable/dataTableElements/file/file.component';
import { DataTableSharedService } from "../components/ijd/generic-table/generic-table-utils/dataTable/dataTableElements/sharedServices/dataTableSharedServices.serivce";
import { DataTableHotStringComponent } from '../components/ijd/generic-table/generic-table-utils/dataTable/dataTableElements/hotString/hotString.component';
import { DataTableStringComponent } from '../components/ijd/generic-table/generic-table-utils/dataTable/dataTableElements/string/string.component';
import { DataTableRouterLinkComponent } from '../components/ijd/generic-table/generic-table-utils/dataTable/dataTableElements/router-link/router-link.component';
import { FollowersGrowthComponent } from '../components/ijd/dashboard/followers-growth/followers-growth.component';
import { OrdersProfitComponent } from '../components/ijd/dashboard/orders-profit/orders-profit.component';
import { ProductStatusChartBoxComponent } from '../components/ijd/dashboard/product-status-chart-box/product-status-chart-box.component';
import { RecentOrdersComponent } from '../components/ijd/dashboard/recent-orders/recent-orders.component';
import { WelcomeComponent } from '../components/ijd/dashboard/welcome/welcome.component';
import { OrdersComponent } from '../components/ijd/dashboard/orders-profit/orders/orders.component';
import { ProfitComponent } from '../components/ijd/dashboard/orders-profit/profit/profit.component';
import { dashboardComponent } from '../components/ijd/dashboard/list/list.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { IJDNgxChartModule } from '../components/ijd/ngx-charts/ngx-chart.module';
@NgModule({
  declarations: [
    FormatViewName,
    SkeletonTableComponent,
    ModelDataCreateComponent,
    // ModelDataComponent,
    GenericTablePipe,
    GenericTableComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    NgbdSortableHeader,
    SvgIconComponent,
    SwiperComponent,
    cssLoaderComponent,
    PaginationComponent,
    ExportComponent,
    DataTableComponent,
    DataTableActionsComponent,
    DataTableImageComponent,
    DataTableStatusComponent,
    DataTableFileComponent,
    DataTableHotStringComponent,
    DataTableStringComponent,
    DataTableRouterLinkComponent,

    dashboardComponent,
    FollowersGrowthComponent,
    OrdersProfitComponent,
    ProductStatusChartBoxComponent,
    RecentOrdersComponent,
    WelcomeComponent,
    OrdersComponent,
    ProfitComponent
  ],
  imports: [
    IJDNgxChartModule,
    CKEditorModule,
    ModelDataModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    // DragulaModule.forRoot(),
    TranslateModule.forRoot(),
    CarouselModule,
    SwiperModule,
    TranslatePipeModule,
    NgApexchartsModule
  ],
  providers: [
    NavService,
    ChatService,
    LayoutService,
    TableService,
    DecimalPipe,
    HttpDataService,
    PdfService,
    DataTableSharedService
  ],
  exports: [
    IJDNgxChartModule,
    CKEditorModule,
    ModelDataCreateComponent,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    GenericTableComponent,
    // ModelDataComponent,
    NgSelectModule,
    ModelDataModule,
    LoaderComponent,
    cssLoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    NgbdSortableHeader,
    SvgIconComponent,
    TranslatePipeModule,
    SwiperModule,
    SkeletonTableComponent,
    PaginationComponent,
    FormatViewName,
    ExportComponent,
    DataTableComponent,
    DataTableActionsComponent,
    DataTableImageComponent,
    DataTableStatusComponent,
    DataTableFileComponent,
    DataTableHotStringComponent,
    DataTableStringComponent,

    FollowersGrowthComponent,
    OrdersProfitComponent,
    ProductStatusChartBoxComponent,
    RecentOrdersComponent,
    WelcomeComponent,
    OrdersComponent,
    ProfitComponent
  ],
})
export class SharedModule { }
