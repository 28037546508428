window.jsPDF = window.jspdf.jsPDF;
export default async function generatePDF(title, data, name, reportName, chart = false, chartDataDates = {}, chartText = "") {
  const doc = new jsPDF('l', 'mm', [297, 210]);

  doc.setTextColor(0);
  doc.setFont("SourceSansPro");
  doc.setFontSize(12);
  // const data = [];
  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(reportName) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const centerX = (pageWidth - textWidth) / 2;
  doc.text(reportName, centerX, 20);
  const underlineY = 14 + 5;
  var tableMarginTop = 20 + 10;
  if (chart) { tableMarginTop += 10 }
  if( chart ){
    doc.text('  : تم إرسال سيرتك الذاتية لجهات التوظيف التالية ', 190, 30, { align: 'right' });
  }
  doc.autoTable({
    startY: tableMarginTop,
    head: [data[0]],
    body: data.slice(1),
    theme: 'grid',
    bodyStyles: { lineColor: [0, 0, 0] },
    headStyles: {
      fillColor: '#808080'
    },
    styles: {
      font: "SourceSansPro",
      fontSize: 10,
      cellWidth: 'auto',
      halign: 'center',
    },
  });
  const borderWidth = 1;
  const borderColor = [0, 0, 0];
  const borderMargin = 5;
  for (let pageNum = 1; pageNum <= doc.getNumberOfPages(); pageNum++) {
    doc.setPage(pageNum);
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const x = borderMargin;
    const y = borderMargin;
    const width = pageWidth - 2 * borderMargin;
    const height = pageHeight - 2 * borderMargin;
    doc.setDrawColor(borderColor[0], borderColor[1], borderColor[2]);
    doc.setLineWidth(borderWidth);
    doc.rect(x, y, width, height);
  }
  doc.setPage(1);
  //top date
  for (let pageNum = 1; pageNum <= doc.getNumberOfPages(); pageNum++) {
    doc.setPage(pageNum);
    doc.text(title, 10, 10);
    doc.text(reportName, centerX, 20);
    // doc.autoTable({
    //   startY: tableMarginTop,
    //   head: [data[0]],
    //   body: data.slice(1),
    //   theme: 'grid',
    //   bodyStyles: { lineColor: [0, 0, 0] },
    //   headStyles: {
    //     fillColor: '#808080'
    //   },
    //   styles: {
    //     font: "SourceSansPro",
    //     fontSize: 10,
    //     cellWidth: 'auto',
    //     halign: 'center',
    //   },
    // });
    const borderWidth = 1;
    const borderColor = [0, 0, 0];
    const borderMargin = 5;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const x = borderMargin;
    const y = borderMargin;
    const width = pageWidth - 2 * borderMargin;
    const height = pageHeight - 2 * borderMargin;
    doc.setDrawColor(borderColor[0], borderColor[1], borderColor[2]);
    doc.setLineWidth(borderWidth);
    doc.rect(x, y, width, height);
  }

  //chart
  if (chart) {
    let startPointFoRchart = doc.autoTable.previous.finalY

    console.log(doc.autoTable.previous)
    if( doc.autoTable.previous.finalY > 200 ){
      doc.addPage();
      doc.setPage(doc.autoTable.previous.pageNumber + 1)
      startPointFoRchart = 20
    }else{
      doc.setPage(doc.autoTable.previous.pageNumber)
    }

    // Generate chart
    const canvas = document.createElement('canvas');
    canvas.width = 800; // Increased width for higher resolution
    canvas.height = 400; // Increased height for higher resolution
    canvas.style.border = '1px solid black'; // Add border around the canvas
    const ctx = canvas.getContext('2d');

    // Sample data for the chart
    const dataChart = chartDataDates.chartData;
    const labels = chartDataDates.labels;

    // Calculate maximum value for scaling
    const maxDataValue = Math.max(...dataChart);

    // Draw bars
    const barWidth = 20; // Adjusted width of the bars
    const barSpacing = 50; // Adjusted spacing between bars
    // const startX = 50;
    // const startY = 50;
    // const chartHeight = canvas.height - 2 * startY;
    const chartHeight = 150;

    const totalChartWidth = dataChart.length * (barWidth + barSpacing);
    const startX = (canvas.width - totalChartWidth) / 2;
    const startY = 50;
    // const chartHeight = canvas.height - 2 * startY;
    const chartWidth = canvas.width - 2 * startX;

    ctx.fillStyle = 'blue';
    ctx.font = '24px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    for (let i = 0; i < dataChart.length; i++) {
      const barHeight = chartHeight * (dataChart[i] / maxDataValue);
      const x = startX + i * (barWidth + barSpacing);
      const y = startY + chartHeight - barHeight;
      ctx.fillRect(x, y, barWidth, barHeight);
      ctx.fillText(dataChart[i], x + barWidth / 2, y - 5); // Display data value on top of the bar
    }

    // Draw labels
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.font = '12px Arial'; // Adjusted font size
    for (let i = 0; i < labels.length; i++) {
      const x = startX + i * (barWidth + barSpacing) + barWidth / 2;
      const y = startY + chartHeight + 35; // Increased vertical padding
      ctx.fillText(labels[i], x, y);
    }

    // Convert canvas content to data URL
    const dataURL = canvas.toDataURL();
    // doc.rect(15, doc.autoTable.previous.finalY + 10, 180, 70, 'S'); // Top border
    const borderWidth = 0.5; // Adjust the thickness as needed
    const x = 15;
    const y = startPointFoRchart + 20;
    const width = 183;
    const height = 70;
    const borderColor = [128, 128, 128]; // Custom color, represented as RGB values (red in this example)
    doc.setDrawColor(borderColor[0], borderColor[1], borderColor[2]); // Set border color

    doc.setLineWidth(borderWidth);
    // doc.line(x, y, x + width, y); // Top border
    // doc.line(x, y + height, x + width, y + height); // Bottom border
    // doc.line(x, y, x, y + height); // Left border
    // doc.line(x + width, y, x + width, y + height); // Right border

    // Add chart image to the PDF document
    // doc.addImage(dataURL, 'PNG', 10, doc.autoTable.previous.finalY + 10, 180, 150); // Adjusted height to accommodate data values on top of bars
    doc.addImage(dataURL, 'PNG', 15, startPointFoRchart + 5, 180, 100); // Adjusted height to accommodate data values on top of bars

    // doc.text(chartText, 190, doc.autoTable.previous.finalY + 80, { align: 'right' });

    var maxWidth = 200; // Maximum width for each line
    var textLines = doc.splitTextToSize(chartText, maxWidth);
    var lineHeight = doc.getTextDimensions(chartText).h;
    var yy = startPointFoRchart + 80;
    textLines.forEach(function (line) {
      doc.text(line, 190, yy, {align: 'right'});
      yy += lineHeight + 4; // Increment y position for the next line
    });

  }
  //endChart

  for (let pageNum = 1; pageNum <= doc.getNumberOfPages(); pageNum++) {
    doc.setPage(pageNum);
    doc.text(title, 10, 10);
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const x = borderMargin;
    const y = borderMargin;
    const width = pageWidth - 2 * borderMargin;
    const height = pageHeight - 2 * borderMargin;
    doc.setDrawColor(borderColor[0], borderColor[1], borderColor[2]);
    doc.setLineWidth(borderWidth);
    doc.rect(x, y, width, height);
  }


  doc.save(name + ".pdf");
  return true
}