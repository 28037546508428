import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { UserInterface } from "src/app/components/ijd-interfaces/user.interface";
import { HttpDataService } from '../../components/ijd-services/httpService.service';
// import { ToastrService } from "ngx-toastr";
import { PipedToasterService } from "../../components/ijd-services/toaster.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(private ActivatedRoute: ActivatedRoute, private fb: FormBuilder, public router: Router, private HttpDataService: HttpDataService, private toastr: PipedToasterService) {
    this.loginForm = this.fb.group({
      email: ["test@test.com", [Validators.required, Validators.email]],
      password: ["test", Validators.required],
    });
  }

  ngOnInit() { }

  loggingIn: boolean = false

  async login() {

    this.loggingIn = true
    let email = this.loginForm.value["email"]
    let password = this.loginForm.value["password"]
    let authData = await this.HttpDataService.post("auth/admin/login", { email, password })
    if (authData.success) {

      this.HttpDataService.logUserIn(authData)
      this.toastr.success(`Login Success`)

      let redirectTo = new URLSearchParams(window.location.href.split("?")[1]).get("redirectTo")
      let urlToRemove = window.location.href.split("auth/login")[0]
      if (redirectTo) {
        return this.router.navigateByUrl(redirectTo.replace(urlToRemove, ""))
        return window.location.href = redirectTo
      } else {
        this.router.navigate(["/dashboard"]);
      }

    } else {
      this.toastr.error(`Login Error`)
      this.loggingIn = false
    }
  }

  showPassword() {
    this.show = !this.show
  }
}
