import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { HttpDataService } from "../../ijd-services/httpService.service";
// import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { PdfService } from "../../ijd-services/pdf.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PipedToasterService } from "../../ijd-services/toaster.service";
import { DataTableComponent } from "./generic-table-utils/dataTable/data-table.component";
import { ExportComponent } from "./generic-table-utils/export-pdf-excel-createButton-FilterButton/export.component";
@Component({
  selector: "app-generic-table",
  templateUrl: "./generic-table.component.html",
  styleUrls: ["./generic-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GenericTableComponent implements OnInit {
  constructor(protected HttpDataService: HttpDataService, private renderer: Renderer2, private router: Router) { }

  @Input("endPoint") endPoint: string;
  @Input("serverResp") serverResp: any;
  @ViewChild("extraStyles") extraStyles: ElementRef
  @ViewChild("appDataTableOptions") appDataTableOptions: ExportComponent

  ngOnInit() {
    this.serverResp = {}; // resets past data if any
    this.endPoint
      ? this.fetchData()
      : this.formatHeaderObject(); // checks if dataTable from backend or passed as serverResp Input
  }

  /**
   * Save Data Before Moving To Another Page
   */
  saveLoadDashboardOptions(newResp: any) {

    if (this.serverResp?.dashboardOptions?.endPoint) { //dont edit if object created before
      // this.appDataTableOptions?.updateInput(this.serverResp)
    }

    // Create Blank Object 
    let savedObject: any = {}

    // If There is old data then will save it to the created object
    if (this.serverResp?.dashboardOptions) savedObject = JSON.parse(JSON.stringify(this.serverResp.dashboardOptions))

    // Adding the data to serverResp variable
    this.serverResp = newResp

    // (( Bug Fix )) Checks if savedObject Keys more than 1, because savedObject will always contains dataLoading key
    savedObject.headers ? this.serverResp.dashboardOptions = savedObject : ""

    // add endpoint input to the object
    this.serverResp.dashboardOptions.endPoint = this.endPoint

  }

  /**
   * Toggles dataLoading variable
   */
  pageLoading(newStatus: boolean) {
    if (!this.serverResp) this.serverResp = {} // creates object if undefined
    if (!this.serverResp?.dashboardOptions) this.serverResp.dashboardOptions = {} // creates dashboard options if undefined

    this.serverResp.dashboardOptions.dataLoading = newStatus // adds the new status
    !newStatus ? this.serverResp.dashboardOptions.ready = true : "" // make pages ready if data loading is false
  }

  /**
   * Sends The request to the backend to fetch the dataTable
   */
  async getDataFromServer() {
    return await this.HttpDataService.get(
      `${this.endPoint}?page=${this.serverResp.dashboardOptions.page}&${this.objectToQueryString(this.serverResp.dashboardOptions.oldFilterData)}`
    );
  }

  /**
   * Checks The Resposne From The Filter Modal
   */
  filterModalData(data: { page: number, filterData: any, modal: any }) {
    this.serverResp.dashboardOptions.page = data.page
    this.serverResp.dashboardOptions.oldFilterData = data.filterData

    if (!data.modal && data?.modal != "skip") return
    //   this.router.navigate([], {
    //     queryParams: { id: null },
    //     queryParamsHandling: 'merge',
    //     skipLocationChange: false,
    //     replaceUrl: true
    // });

    let state = window.location.href.split("?")[1]
    let newState = ""
    if (state) {
      state.split("&").forEach(splittedState => {
        splittedState.split("=")[0] != "id" ? newState += splittedState : ""
      })
      if (newState != "") {
        newState = window.location.pathname + "?" + newState
      } else {
        newState = window.location.pathname
      }
    }
    window.history.replaceState({}, '', newState);

    let filterData = this.serverResp.dashboardOptions.oldFilterData

    this.serverResp.dashboardOptions.savedFilterData = filterData

    data.modal && data.modal != "skip" ? data.modal.close("Finish") : "";

    if (this.areAllValuesEmpty(filterData)) {

      if (!this.serverResp?.dashboardOptions?.tableFiltered && !filterData.refresh) return
      this.serverResp.dashboardOptions.tableFiltered = false;

    } else {
      this.serverResp.dashboardOptions.tableFiltered = true;
    }

  }

  /**
   * Sends Request To Backend
   */
  async fetchData(page: number = 1, filterData: any = {}, modal = undefined) {

    this.pageLoading(true)

    this.filterModalData({ modal, page, filterData })

    this.saveLoadDashboardOptions(await this.getDataFromServer())

    this.formatHeaderObject();

    this.injectExtraStyles()

  }

  /**
   * Convert user filter input to url query object 
   * 
   * eg. {name: "mohamed"} output: name=mohamed
   */
  objectToQueryString(obj: any) {
    let formattedObj = {};
    Object.keys(obj).forEach((key) => {
      if (Array.isArray(obj[key])) {
        if (obj[key].length > 0 && obj[key][0].value) {
          //this will be ngmodel
          formattedObj[key] = obj[key].map((x) => x.value || x).join(",");
        } else if (obj[key].length > 0 && obj[key][0].label) {
          formattedObj[key] = obj[key].map((x) => x.label).join(",");
        } else {
          //this will be normal array
          formattedObj[key] = obj[key].join(",");
        }
      } else {
        if (typeof obj[key] == "object" && obj[key].value && obj[key].label) {
          formattedObj[key] = obj[key].value;
        } else {
          formattedObj[key] = obj[key];
        }
      }
    });

    return Object.keys(formattedObj)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(formattedObj[key])}`
      )
      .join("&");
  }

  /**
   * Checks if obejct is empty
   */
  areAllValuesEmpty(obj: any) {
    for (let key in obj) {
      // Check if the value is not empty
      if (
        (typeof obj[key] === "string" && obj[key].trim() !== "") || // Not an empty string
        (Array.isArray(obj[key]) && obj[key].length !== 0) || // Not an empty array
        (typeof obj[key] === "object" && Object.keys(obj[key]).length !== 0) // Not an empty object
      ) {
        return false; // If any value is not empty, return false
      }
    }
    return true; // If all values are empty
  }

  /**
   * Getting dataTableHeaders From Headers Object
   * 
   * if EMPTY or not found then will take all keys as the headers from first object in data 
   */
  formatHeaderObject() {
    let newHeaders: any = [] // view headers
    let dataHeaders: any = {} // api response data keys

    Object.keys(this.serverResp?.dashboardOptions?.headers).forEach((header: any) => {
      if (header.indexOf("_viewName_") > -1) {
        newHeaders.push(header.split("_viewName_")[1])
        dataHeaders[header.split("_viewName_")[0]] = this.serverResp?.dashboardOptions?.headers[header]
        return
      }
      newHeaders.push(header)
      dataHeaders[header] = this.serverResp?.dashboardOptions?.headers[header]

    })

    this.serverResp.dashboardOptions.headers = dataHeaders
    this.serverResp.dashboardOptions.formattedHeadersView = newHeaders;
    this.serverResp.dashboardOptions.formattedHeaders = Object.keys(this.serverResp?.dashboardOptions?.headers);

    this.pageLoading(false)

  }

  /**
   * Inject Extra Styles Only To This Component
   */
  injectExtraStyles() {
    //make sure first that element is in view
    if (!this.extraStyles?.nativeElement) {
      return setTimeout(() => { this.injectExtraStyles() }, 100)
    }
    let styles = this.serverResp?.dashboardOptions?.extraStyles
    if (!styles) return
    let style = document.createElement("style")
    style.innerHTML = styles
    this.renderer.appendChild(this.extraStyles.nativeElement, style)
  }

  counters = [
    {
      name: "Active Proprties",
      icon: "fa-regular fa-building",
      iconStyle: "margin-left: 2px; font-size: 25px;color: green",
      counter: "10,000",
      roundStyle: "border-color: green",
    },
    {
      name: "Pending Proprties",
      icon: "fa-regular fa-building",
      iconStyle: "margin-left: 2px; font-size: 25px;color: #ffc107",
      counter: "10,000",
      roundStyle: "border-color: #ffc107",
    },
    {
      name: "Total Reservations",
      icon: "fa-regular fa-ticket",
      iconStyle: "margin-left: 2px; font-size: 25px;color: green",
      counter: "10,000",
      roundStyle: "border-color: green",
    },
    {
      name: "Pending Reservations",
      icon: "fa-regular fa-ticket",
      iconStyle: "margin-left: 2px; font-size: 25px;color: #ffc107",
      counter: "10,000",
      roundStyle: "border-color: #ffc107",
    },
    {
      name: "Handled Tickets",
      icon: "fa-regular fa-headset",
      iconStyle: "margin-left: 2px; font-size: 25px;color: green",
      counter: "10,000",
      roundStyle: "border-color: green",
    },
    {
      name: "Pending Tickets",
      icon: "fa-regular fa-headset",
      iconStyle: "margin-left: 2px; font-size: 25px;color: #ffc107",
      counter: "10,000",
      roundStyle: "border-color: #ffc107",
    },
    {
      name: "Sent Notifications",
      icon: "fa-regular fa-envelope",
      iconStyle: "margin-left: 2px; font-size: 25px;color: green",
      counter: "10,000",
      roundStyle: "border-color: green",
    },
    {
      name: "Seen Notifications",
      icon: "fa-regular fa-envelope",
      iconStyle: "margin-left: 2px; font-size: 25px;color: #ffc107",
      counter: "10,000",
      roundStyle: "border-color: #ffc107",
    }
  ]
}
