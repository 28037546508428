export class ElementTracker{
  parent: any
  constructor(parent) { this.parent = parent }
    track() {
        if (document.querySelectorAll("label.error").length > 0) {
          if (!this.isElementInViewport(document.querySelectorAll("label.error")[0])) {
            this.scrollToFirstError()
            setTimeout(() => {
              this.track()
            }, 500)
          }
        } else {
          setTimeout(() => {
            this.track()
          }, 100)
        }
      }

      scrollToFirstError() {
        document.querySelectorAll("label.error")[0]?.scrollIntoView({ behavior: 'smooth', block: "end" })
      }
    
      isElementInViewport(el: any) {
        var rect = el.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }

}