import { Component, Input, OnInit } from '@angular/core';
import * as chartData from '../../../../shared/data/dashboard/default'

@Component({
  selector: 'app-recent-orders',
  templateUrl: './recent-orders.component.html',
  styleUrls: ['./recent-orders.component.scss']
})
export class RecentOrdersComponent implements OnInit {

  @Input("data") data: any

  serverData = {
    chart: {
      height: 350,
      type: "radialBar"
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px"
          },
          value: {
            fontSize: "16px"
          },
          total: {
            show: true,
            label: "Total",
            formatter: (w) => {
              let count = 0
              this.serverData.series.forEach(x=>{
                count += x
              })
              return count;
            }
          }
        }
      }
    },
    series: [44, 55, 67, 83],
    labels: ["Apples", "Oranges", "Bananas", "Berries"],
    viewData: [
      {
        label: "Apples",
        series: 44
      },
      {
        label: "Oranges",
        series: 55
      },
      {
        label: "Bananas",
        series: 67
      },
      {
        label: "Berries",
        series: 83
      }
    ]
  }

  chartOptions = {
    series: [],
    chart: {
      height: 350,
      type: "radialBar"
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px"
          },
          value: {
            fontSize: "16px"
          },
          total: {
            show: true,
            label: "Total",
            formatter: (w) => {
              let count = 0
              this.serverData.series.forEach(x=>{
                count += x
              })
              return count;
            }
          }
        }
      }
    },
    labels: this.serverData.labels
  };

  ;
  public show: boolean = false

  loaded: boolean = false
  constructor() { }

  ngOnInit() {
    this.serverData = this.data
    this.chartOptions.series = this.data.series
    this.chartOptions.labels = this.data.labels
    let viewData = []
    this.data.series.forEach((x, y) => {
      viewData.push({
        label: this.data.labels[y],
        series: x
      })
    })
    this.serverData["viewData"] = viewData
    
    let customStyles = ""
    this.data.colors.forEach((color, i)=>{
      customStyles += `app-recent-orders .order-content li:nth-child(${i+1}) > span { background-color: ${color} !important; }`
    })
    let styleElem = document.createElement("style")
    styleElem.innerHTML = customStyles
    document.querySelector("body").appendChild(styleElem)
    
    this.loaded = true
  }

  toggle() {
    this.show = !this.show
  }
}
