import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'formatViewName',
    pure: true
})
export class FormatViewName implements PipeTransform {

    constructor() { }

    async transform(value: any, arg1: any) {
        if (value?.indexOf("_viewName_") > -1) return value.split("_viewName_")[1]
        return value
    }

}

