<div class="paginationCon" *ngIf="isPaginated && serverResp.data">

    <div class="pagination">

        <button class="paginationButton" (click)="openPage(-1)" style="border: unset">
            < </button>

                <button (click)="openPage(i+1)" class="paginationButton" [ngClass]="{'active': i+1 == currentPage}"
                    *ngFor="let page of [].constructor(totalPages);let i = index">
                    {{i+1}}
                </button>

                <button class="paginationButton" (click)="openPage(-2)" style="border: unset">
                    >
                </button>

    </div>

</div>

<div class="paginationCon" style="margin-top: 10px">

    {{"Records found" | genericTranslate | async}}: {{totalCount}} {{'record(s)' | genericTranslate | async}} .

</div>