import {
    Component,
    ElementRef,
    ViewChild,
} from "@angular/core";

@Component({
    selector: "app-skeleton-table",
    templateUrl: "./skeleton-loading.component.html",
    styleUrls: ["./skeleton-loading.component.scss"],
})

export class SkeletonTableComponent {

    @ViewChild("skeltonTable") skeltonTable: ElementRef
    dataTableHeight: number

    constructor(private elRef: ElementRef) {
        /**
         * Loop In Parent Data Table To Get Current Height
         * To Make Sure While Paginating That Skeleton Height = Current Data
         * Just For Better UI
         */
        setInterval(() => {
            if (this.elRef?.nativeElement?.parentElement?.querySelector(".dataTable")?.offsetHeight) {
                this.dataTableHeight = this.elRef?.nativeElement?.parentElement?.querySelector(".dataTable")?.offsetHeight
                this.fixSekeltonViewWhilePagination()
            }
        }, 500)
    }

    /**
     * Sets The Height Of Skeleton Tabe
     */
    fixSekeltonViewWhilePagination() {
        if (!this.dataTableHeight) return
        let skeltonTable = this.skeltonTable.nativeElement as HTMLElement;
        skeltonTable.setAttribute("style", `height: ${this.dataTableHeight}px`);
    }

}