import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NavService } from "../../../../services/nav.service";
import { HttpDataService } from 'src/app/components/ijd-services/httpService.service';
import { LayoutService } from "src/app/shared/services/layout.service";

@Component({
  selector: "app-languages",
  templateUrl: "./languages.component.html",
  styleUrls: ["./languages.component.scss"],
})
export class LanguagesComponent implements OnInit {

  public layoutType: string = "ltr";
  customizeLayoutType(val) {
    this.layoutType = val;
    this.layout.config.settings.layout_type = val;
    if (val == "rtl") {
      document.getElementsByTagName("html")[0].setAttribute("dir", val);
      document.body.className = "rtl";
    } else if (val == "box-layout") {
      document.getElementsByTagName("html")[0].setAttribute("dir", val);
      document.body.className = "box-layout";
    } else {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.body.className = "";
    }
  }
  
  // public language: boolean = false;

  // public languages: any[] = [
  //   {
  //     language: "English",
  //     code: "en",
  //     type: "US",
  //     icon: "us",
  //   },
  //   {
  //     language: "العربية",
  //     code: "ar",
  //     type: "AR",
  //     icon: "ar",
  //   },
  // ];

  // public selectedLanguage: any =    {
  //   language: "العربية",
  //   code: "ar",
  //   type: "AR",
  //   icon: "ar",
  // }

  constructor(
    public navServices: NavService,
    // private translate: TranslateService
    private HttpDataService: HttpDataService,
    public layout: LayoutService,
  ) {
    this.langs()
  }
  //
  ngOnInit() {}

  availLangs = [{
    id: 1,
    language: "EN"
  }]

  selectedLang = {
    id: 1,
    language: "EN"
  }
  //get available languages
  async langs(){
    let resp = await this.HttpDataService.get("lang/availableLangs")
    if( resp.success && resp.data?.length > 0 ){
      this.availLangs = resp.data
    }

    if( localStorage.getItem("lng") ){
      try{
        let lng = JSON.parse(localStorage.getItem("lng"))
        this.selectedLang = lng
        if( this.selectedLang.language == "AR" ){
          this.customizeLayoutType("rtl")
        }
      }catch(e){

      }
    }
  }

  changeLanguage(lang: any) {
    localStorage.setItem("lng", JSON.stringify(lang))
    if( lang.language == "AR" ){
      this.customizeLayoutType("rtl")
    }
    window.location.reload()
  }
}
