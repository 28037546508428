import { ElementTracker } from './utils/elementTracker';
import { EmailValidation } from './utils/emailValidation';
import { EnumValidation } from './utils/enumValidation';
import { ErrorValidation } from './utils/errorValidation';
import { MinValidation } from './utils/minValidation';
import { RequiredValidation } from './utils/requiredValidation';
import { TypeValidation } from './utils/typeValidation';
import { ClearFilterObject } from './../clearFilterObject';
import { Filter } from './../filter/filter';
import { ResponseInterface } from '../../responseInterface';

export class ImportAllValidations{

    constructor(){}

    data:ResponseInterface

    setData(data){
        this.data = data
    }

    ErrorValidation = new ErrorValidation(this)

    TypeValidation = new TypeValidation(this)

    ElementTracker = new ElementTracker(this)

    RequiredValidation = new RequiredValidation(this)

    EnumValidation = new EnumValidation(this)

    EmailValidation = new EmailValidation(this)

    MinValidation = new MinValidation(this)

    ClearFilterObject = new ClearFilterObject(this)

    Filter = new Filter(this)

    runValidation() {
        this.data.system.validationError = false;
        this.data.system.validationMessages = [];

        Object.keys(this.data.dashboardOptions[this.data.system.targetModel].data).forEach((key) => {

            let keyValue = this.data.dashboardOptions[this.data.system.targetModel].data[key];
            let value = this.data.system.obj[key];

            // add all validations here
            this.TypeValidation.validate(value, key, keyValue)
            this.EnumValidation.validate(value, key, keyValue)
            this.EmailValidation.validate(value, key, keyValue)
            this.MinValidation.validate(value, key, keyValue)
            this.RequiredValidation.validate(value, key, keyValue)

        });

        // to scroll to first error
        if (Object.keys(this.data.system.validationMessages).length > 0) {
            this.ElementTracker.track()
        }

        return {
            validationError: this.data.system.validationError,
            validationMessages: this.data.system.validationMessages
        }
    }

    
}