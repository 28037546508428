export class TypeValidation{
  parent: any
  constructor(parent) { this.parent = parent }
    validate(value: any, key: any, data: any){

        if( data.type == "enum" ) return
        
        if (data.type == "string") {
            if (typeof value !== "string") {
              this.parent.data.system.obj[key] = ""
            }
          } else if (data.type == "number") {
            if (isNaN(value)) {
                this.parent.ErrorValidation.setError(key, "This field is of type number");
            }
          }

    }
}
