<div class="media profile-media">
  <img class="b-r-10" [src]="profileImg" alt="" style="width: 25px">
  <div class="media-body"><span>{{userName}}</span>
    <p class="mb-0 font-roboto">{{'Admin' | genericTranslate | async}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div" style="width: 220px">
  <li (click)="editProfile()"><a><app-feather-icons [icon]="'user'"></app-feather-icons><span>{{'Account' | genericTranslate | async}} </span></a></li>
  <!-- <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Change Password </span></a></li> -->
  <!-- <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li> -->
  <!-- <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li> -->
  <!-- <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>{{'Log out' | genericTranslate | async}}</span></li>
</ul>