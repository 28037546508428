<div class="container-fluid" style="padding-bottom: 25px; padding-top: 25px">

  <app-model-data *ngIf="actions" [options]="actions"></app-model-data>
  <!-- {
    type: type,
    endPoint: endPoint,
    items: actions.createModel.view[0],
    viewOptions: actions.createModel,
    postType: actions.createModel.postType,
    navigateTo: navigateTo
  } -->
</div>