export class EmailValidation {
    parent: any
    constructor(parent) { this.parent = parent }
    validate(value: any, key: string, data?: any) {
        try {

            if (!data.validation?.email) return

            const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                this.parent.ErrorValidation.setError(key, "This field should be a valid email address");
            }
        } catch (e) {
            this.parent.ErrorValidation.setError(key, "This field should be a valid email address");
        }
    }
}