import { ImportAllValidations } from "./validations/importValidations"

export class ClearFilterObject{
    parent: ImportAllValidations
    constructor(parent){this.parent = parent}

    clear() {
        let newObj = {}
        Object.keys(this.parent.data.system.obj).forEach((x: any) => {
          if (this.parent.data.system.obj[x] !== undefined && this.parent.data.system.obj[x] !== null && this.parent.data.system.obj[x].trim !== "") {
            newObj[x] = this.parent.data.system.obj[x]
          }
        })
        this.parent.data.system.obj = newObj
        return newObj
      }
}