import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  public show: boolean = true;

  constructor() {
    setTimeout(() => {
      // this.show = false;
      this.checkAllTranslatePipesLoaded()
    }, 1000);
    // this.checkAllTranslatePipesLoaded()
  }

  checkAllTranslatePipesLoaded(){
    if( document.querySelector("ngx-loading-bar div") ){
      setTimeout(()=>{
        this.checkAllTranslatePipesLoaded()
      })
    }else{
      this.show = false
    }
  }

  ngOnInit() { }

  ngOnDestroy() { }

}
