// Import required modules
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { UserInterface } from "../ijd-interfaces/user.interface";
import { Router } from "@angular/router";

interface OptionsInterface {
  auth?: boolean;
  handleError?: boolean;
  link?: boolean;
  contentType?: string;
  fileName?: string;
}

@Injectable({
  providedIn: "root",
})
export class HttpDataService {
  constructor(private http: HttpClient, private router: Router) {}

  // baseUrl = "http://localhost:4050";
  baseUrl = "https://api.aroovia.com/api";
  getHeaders(auth: boolean, contentType = "") {
    const headers = {};
    if (auth) {
      headers["Authorization"] = `Bearer ${this.getUserToken()}`;
    }
    if (contentType) {
      headers["Content-Type"] = contentType;
    }
    return new HttpHeaders(headers);
  }

  getUserToken() {
    try {
      let userData = JSON.parse(localStorage.getItem("user"));
      return userData.token;
    } catch (e) {
      return false;
    }
  }

  getUserPerms() {
    try {
      let userData = JSON.parse(localStorage.getItem("user"));
      return userData.perms;
    } catch (e) {
      return false;
    }
  }

  logUserIn(data: any) {
    let user: UserInterface = {
      id: data.data.id,
      email: data.data.email,
      name: data.data.name,
      image: data.data.image,
      mobile: data.data.mobile,
      status: data.data.status,
      token: data.token || data.data.token,
      perms: data.perms || data.data.perms,
    };

    localStorage.setItem("user", JSON.stringify(user));
  }

  reLogin() {
    localStorage.removeItem("user");
    this.router.navigateByUrl("auth/login");
  }

  get(
    url: string,
    options: OptionsInterface = { auth: true, handleError: true }
  ): any {
    const headers = this.getHeaders(options.auth);
    return new Promise((resolve) => {
      this.http.get(`${this.baseUrl}/${url}`, { headers }).subscribe(
        (data: any) => {
          !data.success &&
          [
            "Session Was Terminated",
            "Token Expired",
            "Token Validation Error",
          ].indexOf(data.message) > -1
            ? this.reLogin()
            : "";
          if (data.updateToken) {
            let oldUser = JSON.parse(localStorage.getItem("user"));
            oldUser.token = data.updateToken;
            localStorage.setItem("user", JSON.stringify(oldUser));
          }
          resolve(data);
        },
        (error: any) => {
          [
            "Session Was Terminated",
            "Token Expired",
            "Token Validation Error",
          ].indexOf(error.error.message) > -1
            ? this.reLogin()
            : "";
          options.handleError ? resolve({}) : resolve({});
        }
      );
    });
  }

  download(
    url: string,
    options: OptionsInterface = {
      auth: true,
      handleError: true,
      link: false,
      contentType: "",
      fileName: "",
    }
  ): any {
    const headers = this.getHeaders(options.auth, options.contentType);
    return new Promise((resolve) => {
      if (options.link) {
        this.http
          .get(`${this.baseUrl}/${url}`, {
            headers,
            responseType: "blob" as "json",
          })
          .subscribe((response: Blob) => {
            const blobURL = window.URL.createObjectURL(response);
            const link = document.createElement("a");
            link.href = blobURL;
            link.setAttribute("download", options.fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobURL);
            resolve(true);
          });
      } else {
        this.http.post(`${this.baseUrl}/${url}`, { headers }).subscribe(
          (data: any) => {
            !data.success &&
            [
              "Session Was Terminated",
              "Token Expired",
              "Token Validation Error",
            ].indexOf(data.message) > -1
              ? this.reLogin()
              : "";
            if (data.updateToken) {
              let oldUser = JSON.parse(localStorage.getItem("user"));
              oldUser.token = data.updateToken;
              localStorage.setItem("user", JSON.stringify(oldUser));
            }
            resolve(data);
          },
          (error: any) => {
            resolve(error);
          }
        );
      }
    });
  }

  post(
    url: string,
    data: any,
    options: OptionsInterface = {
      auth: true,
      handleError: true,
      link: false,
      contentType: "",
      fileName: "",
    }
  ): any {
    const headers = this.getHeaders(options.auth, options.contentType);
    return new Promise((resolve) => {
      if (options.link) {
        this.http
          .post(`${this.baseUrl}/${url}`, data, {
            headers,
            responseType: "blob" as "json",
          })
          .subscribe((response: Blob) => {
            const blobURL = window.URL.createObjectURL(response);
            const link = document.createElement("a");
            link.href = blobURL;
            link.setAttribute("download", options.fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobURL);
            resolve(true);
          });
      } else {
        this.http.post(`${this.baseUrl}/${url}`, data, { headers }).subscribe(
          (data: any) => {
            !data.success &&
            [
              "Session Was Terminated",
              "Token Expired",
              "Token Validation Error",
            ].indexOf(data.message) > -1
              ? this.reLogin()
              : "";
            if (data.updateToken) {
              let oldUser = JSON.parse(localStorage.getItem("user"));
              oldUser.token = data.updateToken;
              localStorage.setItem("user", JSON.stringify(oldUser));
            }
            resolve(data);
          },
          (error: any) => {
            [
              "Session Was Terminated",
              "Token Expired",
              "Token Validation Error",
            ].indexOf(error.error.message) > -1
              ? this.reLogin()
              : "";
            resolve(error);
          }
        );
      }
    });
  }

  async hasPermission(perm: string[] | undefined) {
    if (perm == undefined) {
      return false;
    }
    let userPerm = this.getUserPerms();
    if (!userPerm) {
      return false;
    }
    let allow: boolean = false;
    userPerm.filter((permName: string) => {
      if (perm.indexOf(permName) > -1) {
        allow = true;
      }
      if (perm.indexOf(permName.replace(/_/g, "")) > -1) {
        allow = true;
      }
    });
    return allow;
  }

  hasPermissionDirect(perm: string[] | undefined) {
    if (perm == undefined) {
      return false;
    }
    let userPerm = this.getUserPerms();
    if (!userPerm) {
      return false;
    }

    if (perm[0] == "auth" || perm[0].indexOf("auth_") > -1) {
      return true;
    }
    let allow: boolean = false;
    userPerm.filter((permName: string) => {
      if (perm.indexOf(permName) > -1) {
        allow = true;
      }
      if (perm.indexOf(permName.replace(/_/g, "")) > -1) {
        allow = true;
      }
    });
    return allow;
  }
}
