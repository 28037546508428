import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ChartistModule } from "ng-chartist";
import { NgChartsModule } from "ng2-charts";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgApexchartsModule } from "ng-apexcharts";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { ModelDataComponent } from "./modelData.component";
import { cssLoaderComponent } from "../cssLoader/cssLoader.component";
import { TranslateModule } from "@ngx-translate/core";
import { TranslatePipeModule } from '../../../ijd-pipes/translate.module';
import { Validations } from "./utils/validations/validation";

@NgModule({
  declarations: [
    ModelDataComponent,
  ],
  imports: [CKEditorModule,TranslatePipeModule, TranslateModule.forRoot(), NgSelectModule, CommonModule, ChartistModule, CarouselModule, NgChartsModule, NgApexchartsModule, NgbModule, FormsModule],
  exports: [
    CKEditorModule,
    ModelDataComponent,
    NgSelectModule,
    TranslateModule
  ]
})
export class ModelDataModule { 
}
