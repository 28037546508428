import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { HttpDataService } from '../../../../../../ijd-services/httpService.service';

@Component({
    selector: "app-data-router-link",
    templateUrl: "./router-link.component.html",
    styleUrls: ["./router-link.component.scss"],
})

export class DataTableRouterLinkComponent implements OnInit {

    constructor(protected HttpDataService: HttpDataService) { }

    @Input("serverResp") serverResp: any
    @Input("data") data: any
    @Input("heading") heading: any

    ngOnInit(): void {
        this.parseParams()
    }

    queryParams = {}
    parseParams(){
        try{
            this.queryParams = this.data[this.heading + '_routerLinkParams']
            console.log("||||||||||||||||||", this.queryParams)
        }catch(e){
        }
    }
}