<!-- exportPDFModal -->
<ng-template #pdfExportContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'PDF Export' | genericTranslate | async}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form class="custom-datepicker custom-datepicker-dropdown">
            <div class="form-group" style="
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            gap: 10px;
            max-width: 215px;
            margin: 0 auto;
            ">
                <button type="button" class="btn btn-primary" (click)="modal.close('exportThisPage')">{{'Export this
                    page' |
                    genericTranslate | async}}</button>
                <button type="button" class="btn btn-light" style="background-color: #03A9F4 !important"
                    (click)="modal.close('exportAllData')"
                    *ngIf="serverResp.dashboardOptions.exportAll && !serverResp.dashboardOptions?.tableFiltered">{{'Export
                    all
                    data' |
                    genericTranslate | async}}</button>
                <button type="button" class="btn btn-light" style="background-color: #03A9F4 !important"
                    (click)="modal.close('exportAllData')"
                    *ngIf="serverResp.dashboardOptions.exportFiltered && serverResp.dashboardOptions?.tableFiltered">{{'Export
                    all
                    filtered
                    data' | genericTranslate | async}}</button>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">{{'Save' |
            genericTranslate |
            async}}</button>
    </div>
</ng-template>

<!-- exportExcelModal -->
<ng-template #excelExportContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'PDF Export' | genericTranslate | async}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form class="custom-datepicker custom-datepicker-dropdown">
            <div class="form-group" style="
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            gap: 10px;
            max-width: 215px;
            margin: 0 auto;
            ">
                <button type="button" class="btn btn-primary" (click)="modal.close('exportThisPage')">{{'Export this
                    page' |
                    genericTranslate | async}}</button>
                <button type="button" class="btn btn-light" style="background-color: #03A9F4 !important"
                    (click)="modal.close('exportAllData')"
                    *ngIf="serverResp.dashboardOptions.exportAll && !serverResp.dashboardOptions?.tableFiltered">{{'Export
                    all
                    data' |
                    genericTranslate | async}}</button>
                <button type="button" class="btn btn-light" style="background-color: #03A9F4 !important"
                    (click)="modal.close('exportAllData')"
                    *ngIf="serverResp.dashboardOptions.exportFiltered && serverResp.dashboardOptions?.tableFiltered">{{'Export
                    all
                    filtered
                    data' | genericTranslate | async}}</button>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">{{'Save' |
            genericTranslate |
            async}}</button>
    </div>
</ng-template>

<!-- filterModal -->
<ng-template #filterModal let-modal>
    <app-model-data *ngIf="serverResp?.dashboardOptions?.ready && serverResp?.dashboardOptions?.filter"
        [oldData]="serverResp.dashboardOptions.oldFilterData" [modal]="modal" (filterData)="sendFetch(1, $event, modal)"
        [options]="serverResp"></app-model-data>
        <!-- {
            type: 'filter',
            items: serverResp.dashboardOptions.filterModel.view[0],
            viewOptions: serverResp.dashboardOptions.filterModel,
          } -->
</ng-template>

<div class="pdfExcelCon" style="align-items: center;" *ngIf="serverResp.dashboardOptions">

    <!-- Create Button -->
    <div>
        <button class="btn btn-primary" *ngIf="
                serverResp.dashboardOptions.create && 
                HttpDataService.hasPermissionDirect([serverResp.dashboardOptions.permPerfix + 'create'])
            " [routerLink]="['/a/create', stringifyObject()]">
            {{'Create New' | genericTranslate | async}}
        </button>
    </div>

    <!-- Filter Button -->
    <div>
        <span class="material-symbols-outlined filterIcon" *ngIf="serverResp.dashboardOptions.filter"
            (click)="openFilterModal(filterModal)">
            filter_alt
        </span>
    </div>

    <!-- Export PDF -->
    <div class="pdfCon">
        <i (click)="downloadPDF(pdfExportContent)"
            [ngClass]="{'iconDisabled' : serverResp.dashboardOptions?.isDownloadingPdf}" *ngIf="
                HttpDataService.hasPermissionDirect([serverResp.dashboardOptions.permPerfix + '_export_all']) &&
                serverResp.data.length > 0 && 
                serverResp.dashboardOptions.pdf && 
                !serverResp.dashboardOptions?.isDownloadingPdf
            " class="fa-solid fa-file-pdf cursor pdf"></i>

        <app-loading *ngIf="serverResp.dashboardOptions?.isDownloadingPdf"></app-loading>
    </div>

    <!-- Export Excel -->
    <div class="excelCon">
        <i (click)="downloadExcel(excelExportContent)"
            [ngClass]="{'iconDisabled' : serverResp.dashboardOptions?.isDownloadingExcel}" *ngIf="
                HttpDataService.hasPermissionDirect([serverResp.dashboardOptions.permPerfix + '_export_all']) && 
                serverResp.data.length > 0 && 
                serverResp.dashboardOptions.excel && 
                !serverResp.dashboardOptions?.isDownloadingExcel
            " class="fa-solid fa-file-excel cursor excel"></i>

        <app-loading *ngIf="serverResp.dashboardOptions?.isDownloadingExcel"></app-loading>
    </div>

</div>