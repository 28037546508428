import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ChartistModule } from "ng-chartist";
import { NgChartsModule } from "ng2-charts";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgApexchartsModule } from "ng-apexcharts";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxChartRoutingModule } from './ngx-chart-routing.module';
import { NgxChartComponent } from "./ngx-chart.component";

@NgModule({
    declarations: [
        NgxChartComponent
    ],
    imports: [NgSelectModule,CommonModule, ChartistModule, CarouselModule, NgChartsModule, NgApexchartsModule, NgbModule, FormsModule, NgxChartRoutingModule],
    exports: [
        NgxChartComponent

    ]
  })
  export class IJDNgxChartModule {}
  