import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { HttpDataService } from "../../ijd-services/httpService.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PipedToasterService } from "../../ijd-services/toaster.service";
import { Validations } from './utils/validations/validation'
import { ResponseInterface, DashboardOptions } from './responseInterface';

@Component({
  selector: "app-model-data",
  templateUrl: "./modelData.component.html",
  styleUrls: ["./modelData.component.scss"],
})
export class ModelDataComponent implements OnInit, OnDestroy {

  editor = ClassicEditor;
  config = {
    toolbar: [
      'undo',
      'redo',
      '|',
      'heading',
      'fontFamily',
      'fontSize',
      '|',
      'bold',
      'italic',
      'underline',
      'fontColor',
      'fontBackgroundColor',
      'highlight',
      '|',
      'link',
      'CKFinder',
      'imageUpload',
      'mediaEmbed',
      '|',
      'alignment',
      'bulletedList',
      'numberedList',
      '|',
      'indent',
      'outdent',
      '|',
      'insertTable',
      'blockQuote',
      'specialCharacters'
    ],
    language: 'id',
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:full',
        'imageStyle:side'
      ]
    },
  }

  ckEditor(obj: string, event: any){
    this.options.system.obj[obj] = event.editor.data.get()
  }

  // @Input("endPoint") endPoint: string;
  // @Input("oldData") oldData: any;
  // @Input("updateId") updateId: number;
  // viewData: any;
  // respData: any;
  // obj: any = {};
  // validationError: boolean = false;
  // validationMessages: any = {};
  // oldDataRaw: any
  // serverResp: any
  // clearFilterObject(obj: any) {
  //   let newObj = {}
  //   Object.keys(obj).forEach((x: any) => {
  //     if (obj[x] !== undefined && obj[x] !== null && obj[x].trim !== "") {
  //       newObj[x] = obj[x]
  //     }
  //   })
  //   return newObj
  // }
  // filter() {
  //   if (this.options.type != "filter") {
  //     this.runValidation();
  //     this.filterData.emit(this.clearFilterObject(this.options.system.obj))
  //     return
  //   }
  //   if (!this.validationError) {
  //     if (this.options.system.obj["startDate"] != "" && this.options.system.obj["endDate"] == "") {
  //       this.toastr.error("Please choose end date first")
  //       return
  //     }

  //     if (this.options.system.obj["endDate"] != "" && this.options.system.obj["startDate"] == "") {
  //       this.toastr.error("Please choose start date first")
  //       return
  //     }

  //     this.filterData.emit(this.clearFilterObject(this.options.system.obj))
  //   }
  // }

  @Input("options") options: ResponseInterface;
  @Input("modal") modal: any;
  @ViewChildren("filterInput") filterInput: QueryList<ElementRef>;
  @ViewChild("startDate") startDate: ElementRef;
  @ViewChild("endDate") endDate: ElementRef;
  @Output() filterData = new EventEmitter<any>();

  showPassword: boolean = false

  loading: boolean = true;


  Validations = new Validations()

  constructor(
    protected HttpDataService: HttpDataService,
    private toastr: PipedToasterService,
    private Router: Router,
    private _location: Location
  ) {
  }

  ngOnInit() {
    this.getModel();
    //@ts-ignore
    if (!this.options.system) (this.options.system = {}, this.options.system.type = "filter")
    this.options.system.validationMessages = {}
    if (!this.options.system.obj) this.options.system.obj = {}
    if (this.options.system.type != "filter") {
      this.options.system.obj = {}
    }
  }

  ngOnDestroy(): void {
    delete this.options.system
  }

  showPasswordF() {
    this.showPassword = !this.showPassword
  }

  getViewName(name: string) {
    return this.options.dashboardOptions[this.options.system.targetModel].data[name]?.name ?? name
  }

  filter() {
    console.log("|", this.options.system.obj)
    this.Validations.setData(this.options)
    this.runService(this.Validations.Filter.filter())
  }

  runService(response: any) {
    if (response.emit) {
      this[response.emit].emit(response.value)
    } else if (response.toaster) {
      this.toastr[response.toaster](response.value)
    }
  }

  runValidation() {
    this.Validations.setData(this.options)
    const { validationError, validationMessages } = this.Validations.runValidation()
    this.options.system.validationError = validationError
    this.options.system.validationMessages = validationMessages
  }

  goBack() {
    this._location.back()
  }

  updateAllowed: boolean = true
  async getModel() {
    if (this.options.system.type == "update") {
      //get old data
      let resp = await this.HttpDataService.get(`${this.options.dashboardOptions.endPoint}/${this.options.dashboardOptions.updateId}`.replace("//", "/"))
      if (resp.success) {
        // added 23-6 to update updateModel on every request
        this.options.dashboardOptions.updateModel = resp.dashboardOptions.updateModel

        this.options.dashboardOptions.oldFilterData = resp.data
        this.options.system.oldDataRaw = resp.data
      } else {
        console.error("SystemError: Could not find any data for that record")
        return
      }

      let hasUpdatePermission = this.HttpDataService.hasPermissionDirect([`${this.options.dashboardOptions.permPerfix}_update`])
      if (!hasUpdatePermission) {
        for (let d in this.options.dashboardOptions.updateModel.data) {
          this.options.dashboardOptions.updateModel.data[d].disabled = true
        }
        this.updateAllowed = false
      }
    }else{
      // fix model data goes to filter model BUG
      this.options.dashboardOptions.oldFilterData = {}
    }
    
    if (this.options.system.type == "filter") {
      this.options.dashboardOptions.oldFilterData = this.options?.dashboardOptions.savedFilterData || {}
    }

    let resp = this.options.dashboardOptions[this.options.system.targetModel]
    // this.serverResp = resp
    // this.respData = this.options.viewOptions.data;

    //view only wanted data
    if (this.options.dashboardOptions[this.options.system.targetModel].view[0] && this.options.dashboardOptions[this.options.system.targetModel].view.length > 0) {
      let viewData = [];
      resp.view.forEach((vv) => {
        let viewDataInner = [];
        vv.forEach((v) => {
          if (this.options.dashboardOptions[this.options.system.targetModel].view[0].indexOf(v) > -1) {
            viewDataInner.push(v);
          }
        });
        viewDataInner.length > 0 ? viewData.push(viewDataInner) : "";
      });

      this.options.system.viewData = viewData
    } else {
      //view all data
      this.options.system.viewData = resp.view
    }

    //filter respData to match viewData
    let newRsepData = {}
    let itemsData = this.options.system.viewData.flatMap(x => x)

    Object.keys(this.options.dashboardOptions[this.options.system.targetModel].data).forEach((key) => {
      if (itemsData.indexOf(key) > -1) {
        newRsepData[key] = this.options.dashboardOptions[this.options.system.targetModel].data[key]
      }
    });

    this.options.dashboardOptions[this.options.system.targetModel].data = newRsepData


    //init values to avoid type strict validation errors
    this.loading = false;

    if (this.options.dashboardOptions.oldFilterData) {
      this.options.system.oldDataRaw = this.options.dashboardOptions.oldFilterData
    }
    this.fixDataViewInNgModel()

    if (this.options.dashboardOptions.oldFilterData?.startDate && this.options.dashboardOptions.oldFilterData?.startDate != "") {
      this.options.system.obj["startDate"] = new Date(this.options.dashboardOptions.oldFilterData["startDate"]).toISOString().slice(0, 16);
    }
    if (this.options.dashboardOptions.oldFilterData?.endDate && this.options.dashboardOptions.oldFilterData?.endDate != "") {
      this.options.system.obj["endDate"] = new Date(this.options.dashboardOptions.oldFilterData["endDate"]).toISOString().slice(0, 16);
    }

  }

  imageAdded(event: any, key?: string) {
    let file = event.target?.files
    if (file && file.length > 0) {
      this.options.system.obj[key ?? "image"] = file[0]
    }
  }

  videoAdded(event: any, name) {
    let file = event.target?.files
    if (file && file.length > 0) {
      this.options.system.obj[name] = file[0]
    }
  }

  fileAdded(event: any, name) {
    let file = event.target?.files
    if (file && file.length > 0) {
      this.options.system.obj[name] = file[0]
    }
  }

  filesAdded(event: any, name) {
    let file = event.target?.files
    if (file && file.length > 0) {
      this.options.system.obj[name] = []
      Object.keys(file).forEach(key => {
        let f = file[key]
        this.options.system.obj[name].push(f)
      })
    }
  }

  updating: boolean = false
  async update() {
    this.updating = true
    this.runValidation();
    if (!this.options.system.validationError) {

      if (this.options.dashboardOptions[this.options.system.targetModel].postType && this.options.dashboardOptions[this.options.system.targetModel].postType == "form") {
        var resp = await this.HttpDataService.post(`${this.options.dashboardOptions.endPoint}/update/${this.options.dashboardOptions.updateId}`.replace("//", "/"), this.createPostData(this.options.system.obj))
      } else {
        var resp = await this.HttpDataService.post(`${this.options.dashboardOptions.endPoint}/update/${this.options.dashboardOptions.updateId}`.replace("//", "/"), this.createPostDataJson(this.options.system.obj))
      }
      if (resp.success) {
        if (this.options.dashboardOptions.endPoint == "users/profile") {
          localStorage.removeItem("user")
          window.location.reload()
        }
        this.toastr.success("Updated Succesfully .")
      } else {
        this.toastr.error(resp?.error?.message ?? "Error, Please try again .")
        this.updating = false
        return
      }
      this.updating = false
      this.filterData.emit({ refresh: true })
    } else {
      // console.log(this.validationError)
      // console.log(this.validationMessages)
    }
    this.updating = false
  }

  createPostData(obj: any) {
    const formData = new FormData();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (key.toLowerCase().indexOf("attachments") > -1) {
          value.forEach(v => {
            formData.append(key, v)
          })
        } else
          if (key.indexOf("image") > -1 || key.toLowerCase().indexOf("video") > -1 || key == "sendProof" || this.options.dashboardOptions[this.options.system.targetModel].data[key].type == "image") {
            typeof obj[key] != 'string' ? formData.append(key, value) : ""
          } else

            if (typeof value === 'object' && !Array.isArray(value)) {
              if (value.value) {
                formData.append(key, value.value)
              } else if (value.label) {
                formData.append(key, value.label)
              } else if (typeof value == "string") {
                formData.append(key, value)
              }
            } else if (Array.isArray(value) && value.length > 0 && (value[0].value || value[0].label)) {
              value.forEach(val => {
                if (val.value) {
                  formData.append(key, val.value)
                } else if (val.label) {
                  formData.append(key, val.label)
                } else if (typeof val == "string") {
                  formData.append(key, val)
                }
              })
            } else {
              formData.append(key, value);
            }
      }
    }
    formData.append("token", JSON.parse(localStorage.getItem("user")).token);
    return formData;
  }

  createPostDataJson(obj: any) {
    let formattedObj = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (key == "image") {
          formattedObj[key] = value
        } else

          if (typeof value === 'object' && !Array.isArray(value)) {
            if (value.value) {
              formattedObj[key] = value.value
            } else if (value.label) {
              formattedObj[key] = value.label
            }
          } else if (Array.isArray(value)) {
            formattedObj[key] = []
            value.forEach(val => {
              if (val.value) {
                formattedObj[key].push(val.value)
              } else if (val.label) {
                formattedObj[key].push(val.label)
              }
            })
          } else {
            formattedObj[key] = value
          }
      }
    }
    return formattedObj;
  }

  creating: boolean = false
  async create() {
    this.creating = true
    this.runValidation();
    if (!this.options.system.validationError && Object.keys(this.options.system.validationMessages).length == 0) {

      if (this.options.dashboardOptions[this.options.system.targetModel].postType && this.options.dashboardOptions[this.options.system.targetModel].postType == "form") {
        var resp = await this.HttpDataService.post(`${this.options.dashboardOptions.endPoint}/create`.replace("//", "/"), this.createPostData(this.options.system.obj))
      } else {
        var resp = await this.HttpDataService.post(`${this.options.dashboardOptions.endPoint}/create`.replace("//", "/"), this.createPostDataJson(this.options.system.obj))
      }
      if (resp.success) {
        this.toastr.success("Created Succesfully .")
      } else {
        this.toastr.error(resp?.error?.message ?? "Error, Please try again .")
        this.creating = false
        // this.toastr.error("Error, Please try again .")
        return
      }
      this.creating = false

      if (this.options.system.navigateTo || this.options.dashboardOptions.navigateTo) {
        if (this.options.system.navigateTo == "back" || this.options.dashboardOptions.navigateTo == "back") {
          this.goBack()
        } else {
          this.Router.navigate([this.options.system.navigateTo || this.options.dashboardOptions.navigateTo])
        }
      } else if (this.options.system.navigateToParams) {
        this.Router.navigateByUrl(`/a?path=${this.options.system.navigateToParams}`)
      } else {
        this.filterData.emit({ refresh: true })
      }
    } else {
      // console.log(this.validationError)
      // console.log(this.validationMessages)
    }
    this.creating = false
  }


  fixDataViewInNgModel() {

    let newOldData: any = {}
    this.options.dashboardOptions.oldFilterData?.startDate ? newOldData.startDate = this.options.dashboardOptions.oldFilterData?.startDate : ""
    this.options.dashboardOptions.oldFilterData?.endDate ? newOldData.endDate = this.options.dashboardOptions.oldFilterData?.endDate : ""
    //first we need to get all keys in both
    let keys = [... new Set([...Object.keys(this.options.dashboardOptions[this.options.system.targetModel].data), ...Object.keys(this.options.dashboardOptions.oldFilterData)])]
    keys.forEach(key => {
      let type = this.options.dashboardOptions[this.options.system.targetModel].data[key]?.type
      let val = this.options.system.oldDataRaw[key]

      if (!type || !val) { return }
      if (type == 'string' || type == 'number' || type == 'image' || type == 'ckeditor') {
        newOldData[key] = val
      } else if (type == 'date') {
        newOldData[key] = val?.split("T")[0]
      } else if (val && type.indexOf('ngModel') > -1) {
        if (type.indexOf('Single') > -1) {
          if (!val.label) {
            let baseEnum = {
              label: val,
              value: val
            }
            newOldData[key] = baseEnum
          } else {
            newOldData[key] = val
          }
        } else {
          let enumArr = []
          if (Array.isArray(val) && !val[0]?.label) {
            val.forEach(v => {
              enumArr.push({
                label: v,
                value: v
              })
            })
          } else if (typeof val == 'string') {
            val.split(",").forEach(v => {
              enumArr.push({
                label: v,
                value: v
              })
            })
          } else if (enumArr.length == 0 && val[0]?.label) {
            enumArr = val
          }

          newOldData[key] = enumArr.filter(x => {
            if (x.value && x.value != '') {
              return x
            }
            if(typeof x == "string"){
              return {
                value: x,
                label: x
              }
            }
          }).map(x=>{
            if(typeof x == "string"){
              return{
                label: x,
                value: x
              }
            }
            return x
          })

        }
      }
    })

    this.options.system.obj = newOldData

  }

  close() {
    this.filterData.emit({ refresh: true })
    this.modal.close("Save click")
  }

  openImage(url) {
    let anchor = document.createElement("a")
    anchor.target = "_blank"
    anchor.href = url
    document.querySelector("body").appendChild(anchor)
    anchor.click()
    anchor.remove()
  }

}
