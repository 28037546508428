import { Component, ElementRef, Input, QueryList, ViewChildren } from "@angular/core";
import { HttpDataService } from '../../../../../../ijd-services/httpService.service';
import { PipedToasterService } from "src/app/components/ijd-services/toaster.service";

@Component({
    selector: "app-data-table-hotString",
    templateUrl: "./hotString.component.html",
    styleUrls: ["./hotString.component.scss"],
})

export class DataTableHotStringComponent {

    constructor(protected HttpDataService: HttpDataService, private toastr: PipedToasterService) { }

    @Input("serverResp") serverResp: any
    @Input("data") data: any
    @Input("heading") heading: any
    @Input("i") i: number

    async hotSave(obj: any, key: any, indexOfData: number) {
        console.log("1")
        if (this.serverResp?.data[indexOfData][key + '_mod'] && (obj[key] != this.serverResp?.data[indexOfData][key + '_org'])) {
            console.log("2")
            let id = obj[key + "_id"]
            console.log("3")
            let url = obj.saveUrl
            console.log("4")
            let value = obj[key]
            console.log("5")
            let resp = await this.HttpDataService.post(`${url}/${id}`, { translated: value })
            console.log("6")
            if (resp.success) {
                this.toastr.success("Record updated successfully")
                this.serverResp.data[indexOfData][key + '_org'] = value
                this.serverResp.data[indexOfData][key + '_mod'] = false
            }
        }
    }
}