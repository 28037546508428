import { Component, ElementRef, Input, QueryList, ViewChildren } from "@angular/core";
import { HttpDataService } from '../../../../../../ijd-services/httpService.service';

@Component({
    selector: "app-data-table-file",
    templateUrl: "./file.component.html",
    styleUrls: ["./file.component.scss"],
})

export class DataTableFileComponent {

    constructor(protected HttpDataService: HttpDataService) { }

    @Input("serverResp") serverResp: any
    @Input("data") data: any
    @Input("heading") heading: any

}