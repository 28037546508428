import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { _GenericTranslatePipe } from "./translate.pipe"

@NgModule({
  declarations: [
    _GenericTranslatePipe
  ],
  imports: [
  ],
  exports: [
    _GenericTranslatePipe
  ],
})
export class TranslatePipeModule { 

}
