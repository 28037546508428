<div style="margin-top: 20px" *ngIf="serverResp.chartType">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [labels]="chartOptions.labels"
      [title]="chartOptions.title"
      [plotOptions]="chartOptions.plotOptions"
      [responsive]="chartOptions.responsive"
    ></apx-chart>
  </div>
  <!-- [dataLabels]="chartOptions.dataLabels" -->
