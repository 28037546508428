<!-- <ng-container *ngIf="serverResp?.dashboardOptions?.headers[heading].indexOf('routerLink_') > -1">
    <a class="stopNav" [routerLink]="serverResp?.dashboardOptions?.headers[heading].split('routerLink_')[1].split('_')[0]" [queryParams]="queryParams">
        {{data[heading]}}
    </a>
</ng-container> -->

<ng-container *ngIf="serverResp?.dashboardOptions?.headers[heading] == 'routerLink'">
    <a class="stopNav" [routerLink]="data[heading + '_routerLink']" [queryParams]="queryParams">
        <span *ngIf="data[heading]">{{data[heading]}}</span>
        <span *ngIf="!data[heading]"><i class="arrow fa-solid fa-arrow-right"></i></span>
    </a>
</ng-container>
