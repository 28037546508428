<span #stopNavigation class="stopNav statusContainer" *ngIf="serverResp?.dashboardOptions?.headers[heading] == 'status'"
  [ngClass]="{ 
    'red': data[heading] == 'disabled' || data[heading] == 'pending', 
    'green': data[heading] == 'active' || data[heading] == 'handled' 
    }">

  <span class="statusCircle"
    [ngClass]="{ 
      'redCircle': data[heading] == 'disabled' || data[heading] == 'pending', 
      'greenCircle': data[heading] == 'active' || data[heading] == 'handled' 
      }">
  </span>

  {{data[heading] | genericTranslate | async}}

</span>

<span #stopNavigation class="stopNav statusContainer" *ngIf="serverResp?.dashboardOptions?.headers[heading] == 'reservationStatus'"
  [ngClass]="{ 
    'red': data[heading] == 'cancelled' || data[heading] == 'pendingPaymentTimeout', 
    'green': data[heading] == 'completed',
    'yellow': data[heading] == 'pendingPayment' || data[heading] == 'pending',
    }">

  <span class="statusCircle"
    [ngClass]="{ 
      'redCircle': data[heading] == 'cancelled' || data[heading] == 'pendingPaymentTimeout', 
      'greenCircle': data[heading] == 'completed',
      'yellowCircle': data[heading] == 'pendingPayment' || data[heading] == 'pending',
      }">
  </span>

  {{data[heading] | genericTranslate | async}}

</span>