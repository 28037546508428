<span *ngIf="serverResp?.dashboardOptions?.headers[heading] == 'hotString'" style='position: relative'
    class="hotInputSpan">
    <input (keyup.enter)="hotSave(data, heading, i)" (keydown)="data[heading + '_mod'] = 'true'"
        [(ngModel)]="data[heading]" style="
                                border: .1px solid #0000001a;
                                outline: .1px solid #0000004a;
                                border-radius: 6px;
                                padding: 7px;" />

    <span (click)="hotSave(data, heading, i)" *ngIf="data[heading + '_mod'] && data[heading + '_org'] != data[heading]">
        <i class="fa-regular fa-floppy-disk"></i>
    </span>
    <span (click)="data[heading] = data[heading + '_org']"
        *ngIf="data[heading + '_mod'] && data[heading + '_org'] != data[heading]">
        <i class="fa-solid fa-ban"></i>
    </span>
</span>