import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexPlotOptions,
  ApexResponsive,
  ApexNonAxisChartSeries,
  ChartComponent
} from 'ng-apexcharts';
import { ResponseInterface } from '../modelData/responseInterface';

export type ChartOptions = {
  // series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  // chart: ApexChart;
  // xaxis?: ApexXAxis;
  // dataLabels: ApexDataLabels;
  // title: ApexTitleSubtitle;
  // plotOptions?: ApexPlotOptions;
  // responsive?: ApexResponsive[];
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  chart: ApexChart;
  xaxis?: ApexXAxis;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  labels?: string[]; // Add labels for non-axis charts like pie charts
};

@Component({
  selector: 'app-ngx-chart',
  templateUrl: './ngx-chart.component.html',
  styleUrls: ['./ngx-chart.component.scss'],

})
export class NgxChartComponent implements OnInit {
  @Input() serverResp: ResponseInterface
  @Input() chartType: any = 'polarArea'; // default to 'bar'

  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: this.chartType
      },
      title: {
        text: 'Dynamic Chart'
      },
      xaxis: {
        categories: []
      },
      dataLabels: {
        enabled: false
      }
    };
  }

  ngOnInit(): void {
    this.formatChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.serverResp || changes.chartType) {
      this.formatChartData();
    }
  }

  private formatChartData(): void {
    if (!this.serverResp.chartData) return
    this.chartOptions.chart.type = this.serverResp.chartType;
    this.chartOptions.title.text = this.serverResp.chartName;

    if(this.chartOptions.chart.type != 'bar'){
      this.chartOptions.series = this.serverResp.chartData.map(item => item.data);
      this.chartOptions.labels = this.serverResp.chartData.map(item => item.name);
      delete this.chartOptions.xaxis
    }else{
      this.chartOptions.series = this.serverResp.chartData;
      this.chartOptions.xaxis = {
        categories: this.serverResp.chartCat
      };
  
    }

    // if (this.isNonAxisChart(this.chartType)) {
    //   this.chartOptions.series = this.serverResp.chartData.map(item => item.label);
    //   delete this.chartOptions.xaxis;
    // } else {
    //   this.chartOptions.series = this.serverResp.chartData;
    //   this.chartOptions.xaxis = {
    //     categories: this.serverResp.chartData.map(item => item.name)
    //   };
    // }
  }

  private isNonAxisChart(chartType: string): boolean {
    // List of non-axis charts in ApexCharts
    const nonAxisCharts = ['pie', 'donut', 'radialBar', 'polarArea'];
    return nonAxisCharts.includes(chartType);
  }
}
