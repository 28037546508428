<div *ngIf="!loading" class="container-fluid base-inputs" style="padding: unset">
  <div class="card" style="margin-bottom: unset !important">
    <div class="card-header">
      <h5 *ngIf="options.system.type == 'filter'">{{'Apply filters !' | genericTranslate | async}}</h5>
      <h5 *ngIf="options.system.type == 'create'">{{'Create new record !' | genericTranslate | async}}</h5>
      <h5 *ngIf="options.system.type == 'update' && !options?.dashboardOptions?.title">{{'Update existing record !' |
        genericTranslate | async}}</h5>
      <h5 *ngIf="options?.dashboardOptions?.title">{{options.dashboardOptions.title | genericTranslate | async}}</h5>
    </div>
    <form class="form theme-form">
      <div class="card-body">
        <div class="row">

          <div *ngIf="options?.system?.oldDataRaw?.innerHTML" [innerHTML]="options.system.oldDataRaw.innerHTML"
            style="text-align: center;padding-bottom: 60px;"></div>

          <div *ngFor="let view of options.system.viewData" class="col">
            <div *ngFor="let v of view" class="mb-3 row">
              <label class="col-sm-3 col-form-label cap" [ngClass]="{'error': options.system?.validationMessages[v]}">
                {{getViewName(v) |
                genericTranslate | async}}</label>
              <div class="col-sm-9">
                <!-- Start Of Generic Types -->

                <!-- Show Old Value Of Input -->
                <span
                  *ngIf="options.dashboardOptions.editHint && options.dashboardOptions.oldFilterData[v] && (options.dashboardOptions[options.system.targetModel].data[v].type.toLowerCase().indexOf('video') == -1) && (options.dashboardOptions[options.system.targetModel].data[v].type != 'image') && (options.dashboardOptions[options.system.targetModel].data[v].type != 'file') && (options.dashboardOptions[options.system.targetModel].data[v].type != 'files')">
                  {{'Hint' | genericTranslate | async}}: {{options.dashboardOptions.oldFilterData[v]}}
                </span>
                <!-- End Show Old Value Of Input -->

                <!--ckeditor-->
                <ckeditor *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'ckeditor'" [editor]="editor" [config]="config" [data]="options.system.obj[v]" (change)="ckEditor(v, $event)"></ckeditor>

                <!-- If Input Type Is Normal String/Text -->
                <input [disabled]="options.dashboardOptions[options.system.targetModel].data[v].disabled"
                  *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'string' && !options.dashboardOptions[options.system.targetModel].data[v].password"
                  class="form-control" #filterInput [name]="v" [(ngModel)]="options.system.obj[v]">
                <input style="position: relative" [type]="showPassword ? 'text' : 'password'"
                  *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'string' && options.dashboardOptions[options.system.targetModel].data[v].password"
                  class="form-control" #filterInput [name]="v" [(ngModel)]="options.system.obj[v]">
                <div style="top: 23px; right: 25px" class="show-hide" (mousedown)="showPasswordF()"
                  (mouseout)="showPassword = false"
                  *ngIf="!showPassword && options.dashboardOptions[options.system.targetModel].data[v].password"><i
                    class="fa-solid fa-eye-slash"></i></div>
                <div style="top: 23px; right: 25px" class="show-hide" (click)="showPasswordF()"
                  (mouseout)="showPassword = false"
                  *ngIf="showPassword && options.dashboardOptions[options.system.targetModel].data[v].password"><i
                    class="fa-solid fa-eye"></i></div>
                <!-- End If Input Type Is Normal String/Text -->


                <!-- If Input Type Is Number -->
                <input *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'number'"
                  type="number" class="form-control" #filterInput [name]="v" [(ngModel)]="options.system.obj[v]">
                <!-- End If Input Type Is Number -->

                <!-- If Input Type Is Date -->
                <input *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'date'" type="date"
                  class="form-control" #filterInput [name]="v" [(ngModel)]="options.system.obj[v]">
                <!-- End If Input Type Is Date -->

                <!-- If Input Type Is NgModel With No Restrictions -->
                <ng-select #filterInput [name]="v"
                  [items]="options.dashboardOptions[options.system.targetModel].data[v].ngModelItems"
                  [editableSearchTerm]="true" [addTag]="true"
                  *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'ngModel'"
                  class="js-example-basic-multiple" placeholder="" [(ngModel)]="options.system.obj[v]"
                  [multiple]="true"></ng-select>
                <!-- End If Input Type Is NgModel With No Restrictions -->

                <!-- If Input Type Is NgModel With Restrictions -->
                <ng-select #filterInput [name]="v"
                  *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'ngModelEnum'"
                  [items]="options.dashboardOptions[options.system.targetModel].data[v].ngModelItems"
                  class="js-example-basic-multiple" placeholder="{{'Select' | genericTranslate | async}}"
                  [(ngModel)]="options.system.obj[v]" [multiple]="true"></ng-select>
                <!-- End If Input Type Is NgModel With Restrictions -->

                <!-- If Input Type Is SINGLE NgModel With Restrictions -->
                <ng-select #filterInput [name]="v"
                  *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'ngModelEnumSingle'"
                  [items]="options.dashboardOptions[options.system.targetModel].data[v].ngModelItems"
                  class="js-example-basic-multiple" placeholder="{{'Select' | genericTranslate | async}}"
                  [(ngModel)]="options.system.obj[v]" [multiple]="false"></ng-select>
                <!-- End If Input Type Is SINGLE NgModel With Restrictions -->

                <!-- If Input Type Is Image -->
                <ng-container *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'image' && options.system.type == 'update' && ( options.dashboardOptions.oldFilterData[v] || options.dashboardOptions.oldFilterData[v.replace('_image', '')] )">
                  <img
                    (click)="openImage( (options.dashboardOptions.oldFilterData[v] || options.dashboardOptions.oldFilterData[v.replace('_image','')]))"
                    class="oldImage cursor" #filterInput [name]="v"
                    *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'image' && options.system.type == 'update'"
                    [src]=" (options.dashboardOptions.oldFilterData[v] || options.dashboardOptions.oldFilterData[v.replace('_image','')])" />
                  <input style="margin-bottom: 60px" #filterInput [name]="v"
                    *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'image' && !options.dashboardOptions[options.system.targetModel].data[v].readOnly"
                    class="form-control" type="file" (change)="imageAdded($event, v)"
                    accept="image/jpg,image/png,image/jpeg" />
                </ng-container>
                <ng-container *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'image' && options.system.type == 'create' ">
                <input style="margin-bottom: 60px" #filterInput [name]="v"
                    *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'image' && !options.dashboardOptions[options.system.targetModel].data[v].readOnly"
                    class="form-control" type="file" (change)="imageAdded($event, v)"
                    accept="image/jpg,image/png,image/jpeg" />
                </ng-container>
                <ng-container *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'image' && options.system.type == 'update' && ( !options.dashboardOptions.oldFilterData[v] && !options.dashboardOptions.oldFilterData[v.replace('_image', '')] )">
                  <br>
                  No Image Found
                  <br>
                  <input style="margin-bottom: 60px" #filterInput [name]="v"
                  *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'image' && !options.dashboardOptions[options.system.targetModel].data[v].readOnly"
                  class="form-control" type="file" (change)="imageAdded($event, v)"
                  accept="image/jpg,image/png,image/jpeg" />
                </ng-container>
                <!-- End If Input Type Is Image -->

                <!-- If Input Type Is Video -->
                <a class="oldVideo" #filterInput
                  *ngIf="options.system.type == 'update' && v && options.dashboardOptions.oldFilterData && options.dashboardOptions.oldFilterData[v] && options.dashboardOptions[options.system.targetModel].data[v]?.type == 'video'"
                  target="_blank"
                  [href]=" options.dashboardOptions.oldFilterData[v]"
                  [name]="v">Download
                  Video</a>
                <input #filterInput
                  *ngIf="v && options.dashboardOptions[options.system.targetModel].data[v]?.type == 'video'" [name]="v"
                  class="form-control" type="file" (change)="videoAdded($event, v)"
                  accept="video/mp4,video/x-m4v,video/*" />
                <!-- End If Input Type Is Video -->

                <!-- Single Files Upload -->
                <span
                  *ngIf="options.system.type == 'update' &&  options.dashboardOptions.oldFilterData && options.dashboardOptions.oldFilterData[v] && options.dashboardOptions[options.system.targetModel].data[v]?.type == 'file'">
                  <a target="_blank" *ngIf="options.dashboardOptions.oldFilterData[v]" class="oldFiles" #filterInput
                    [name]="v"
                    [href]="options.dashboardOptions.oldFilterData[v]">Download
                    File</a>
                </span>
                <span
                *ngIf="options.system.type == 'update' && !options.dashboardOptions.oldFilterData[v] && options.dashboardOptions[options.system.targetModel].data[v]?.type == 'file'">
                <a target="_blank" *ngIf="!options.dashboardOptions.oldFilterData[v]" class="oldFiles" #filterInput
                  [name]="v"
                  >No File Found</a>
              </span>
                <input #filterInput [name]="v"
                  *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'file' && !options.dashboardOptions[options.system.targetModel].data[v].readOnly"
                  class="form-control" type="file" (change)="fileAdded($event, v)" />
                <!-- End Multiple Files Upload -->

                <!-- Multiple Files Upload -->
                <span
                  *ngIf="options.system.type == 'update' &&  options.dashboardOptions.oldFilterData && options.dashboardOptions.oldFilterData[v] && options.dashboardOptions[options.system.targetModel].data[v]?.type == 'files'">
                  <span *ngFor="let f of options.dashboardOptions.oldFilterData[v].split(','); let i = index">
                    <a target="_blank" *ngIf="f" class="oldFiles" #filterInput [name]="v"
                      [href]=" f">Download File ({{i}})</a>
                    <br>
                  </span>
                </span>
                <input #filterInput [name]="v"
                  *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'files'"
                  class="form-control" type="file" multiple="true" (change)="filesAdded($event, v)" />
                <!-- End Multiple Files Upload -->

                <!-- Error Messages -->
                <span class="error" *ngIf="options.system?.validationMessages[v]" class="error">
                  {{options.system?.validationMessages[v] | genericTranslate | async}}
                </span>
                <!-- End Error Messages -->

                <!-- Filter By Date -->
                <span *ngIf="options.dashboardOptions[options.system.targetModel].data[v].type == 'rangeDate'">
                  <div class="mb-3 row">
                    <label class="col-sm-3 col-form-label">{{'Start date' | genericTranslate | async}}</label>
                    <div class="col-sm-9">
                      <input class="form-control digits" name="startDate" id="example-datetime-local-input"
                        type="datetime-local" [(ngModel)]="options.system.obj['startDate']" #startDate />
                    </div>
                  </div>
                  <div class="mb-3 row">
                    <label class="col-sm-3 col-form-label">{{'End date' | genericTranslate | async}}</label>
                    <div class="col-sm-9">
                      <input class="form-control digits" name="endDate" id="example-datetime-local-input"
                        type="datetime-local" [(ngModel)]="options.system.obj['endDate']" #endDate />
                    </div>
                  </div>
                </span>
                <!-- End Filter By Date -->


                <!-- End Of Generic Types -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-end">
        <div class="col-sm-9 offset-sm-3">
          <button class="btn btn-primary" type="submit" (click)="filter()"
            *ngIf="options.system.type == 'filter'">{{'Filter' |
            genericTranslate | async}}</button>
          <button class="btn btn-primary" [disabled]="updating" type="submit" (click)="update()"
            *ngIf="options.system.type == 'update' && updateAllowed">{{'Update' | genericTranslate | async}}<span
              *ngIf="updating" class="loader"></span></button>
          <button class="btn btn-primary" [disabled]="creating" type="submit" (click)="create()"
            *ngIf="options.system.type == 'create'">{{'Create' | genericTranslate | async}}<span *ngIf="creating"
              class="loader"></span></button>
          <input class="btn btn-light ms-1 cancel-button" type="reset" value="Cancel"
            (click)="modal ? close() : goBack()" />
        </div>
      </div>
    </form>
  </div>
</div>