import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from "@angular/core";

@Component({
    selector: "app-paginated-table",
    templateUrl: "./pagination.component.html",
    styleUrls: ["./pagination.component.scss"],
})

export class PaginationComponent implements OnInit, OnChanges {

    @Input("serverResp") serverResp: any
    @Output("openPageEvent") openPageEvent: EventEmitter<any> = new EventEmitter()

    ngOnInit() { this.init() }
    ngOnChanges() { this.init() }

    isPaginated: boolean = false;
    totalPages: number;
    currentPage: number;
    totalCount: number;
    parentComponent: any

    init() {
        this.serverResp.data ? this.calculatePages() : ""
    }

    /**
     * Checks If Server Response Pagianted Or Not
     * By Checking currentPage KEY
     */
    calculatePages() {

        if (!this.serverResp.currentPage) {
            this.totalCount = this.serverResp.data.length;
            this.isPaginated = false
            return;
        }

        this.isPaginated = true;
        const { currentPage, totalPages, totalData } = this.serverResp;
        this.totalPages = totalPages;
        this.currentPage = currentPage;
        this.totalCount = totalData;

    }

    /**
     * Emits To Parent The Page User Seleccted
     * -1 EQUALS to previous page
     * -2 EQUALS to next page
     */
    openPage(page: number) {

        page == -1 ? (page = this.currentPage - 1) : "";
        page == -2 ? (page = this.currentPage + 1) : "";

        if (page > this.totalPages || page < 1) {
            return;
        }

        this.openPageEvent.emit(page)
    }

}