<div class="card growth-wrap" style="height: 94%;">
  <div class="card-header card-no-border">
    <div class="header-top">
      <h5>{{'Visitors' | genericTranslate | async}}</h5>
    </div>
  </div>
  <div class="card-body pt-0">
    <div class="growth-wrapper">
      <div id="growthchart">
        <apx-chart
          *ngIf="loaded"
          [series]="followersGrowth.series"
          [chart]="followersGrowth.chart"
          [grid]="followersGrowth.grid"
          [colors]="followersGrowth.colors"
          [stroke]="followersGrowth.stroke"
          [xaxis]="followersGrowth.xaxis"
          [yaxis]="followersGrowth.yaxis"
        ></apx-chart>
      </div>
    </div>
  </div>
</div>
