<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage" />
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" /> -->
              <img class="img-fluid for-light" src="assets/foras-logo.png" alt="looginpage" style="max-width: 300px" />
              <img class="img-fluid for-dark" src="assets/foras-logo.png" alt="looginpage" style="max-width: 300px" />
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>{{'Sign in to account' | genericTranslate | async}}</h4>
              <p>{{'Enter your email & password to login' | genericTranslate | async}}</p>
              <div class="form-group">
                <label class="col-form-label">{{'Email Address' | genericTranslate | async}}</label>
                <input class="form-control" type="email" required="" placeholder="{{'app@yourmail.com' | genericTranslate | async}}" formControlName="email" />
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required" class="text text-danger mt-1">{{'Email is required' | genericTranslate | async}}</div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email" class="text text-danger mt-1">{{'Invalid Email' | genericTranslate | async}}</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">{{'Password' | genericTranslate | async}}</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********" />
                <!-- <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div> -->
                <!-- <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div> -->
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">{{'Password is required' | genericTranslate | async}}</div>
              </div>
              <div class="form-group mb-0">
                <!-- <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" />
                  <label class="text-muted" for="checkbox1">Remember password</label>
                </div>
                <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a> -->
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid || loggingIn" (click)="login()" type="button"><span>{{'Sign in' | genericTranslate | async}}</span><app-loading *ngIf="loggingIn"></app-loading></button>
                </div>
              </div>
              <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6>
              <div class="social mt-4">
                <div class="btn-showcase">
                  <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'log-in'" class="txt-google"></app-feather-icons>Google + </a>
                  <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>Facebook </a>
                  <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter </a>
                </div>
              </div>
              <p class="mt-4 mb-0">
                Don't have account?
                <a [routerLink]="'/authentication/register/simple'" class="ms-2">Create Account</a>
              </p> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
