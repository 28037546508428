import {
    Component,
    ElementRef,
    Input,
    Output,
    QueryList,
    ViewChildren,
    EventEmitter,
    ViewChild,
    OnInit,
    AfterViewChecked,
    AfterViewInit,
    OnChanges
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpDataService } from "src/app/components/ijd-services/httpService.service";
import { PipedToasterService } from "src/app/components/ijd-services/toaster.service";
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableSharedService } from "./dataTableElements/sharedServices/dataTableSharedServices.serivce";

@Component({
    selector: "app-data-table",
    templateUrl: "./data-table.component.html",
    styleUrls: ["./data-table.component.scss"],
})

export class DataTableComponent implements OnInit, AfterViewInit, OnChanges {

    @Input("serverResp") serverResp: any
    @Output("fetchData") fetchDataOutput: EventEmitter<any> = new EventEmitter()

    @ViewChild("updateModal") updateModal: any;
    @ViewChild("dataTable") dataTable: ElementRef;
    @ViewChildren("stopNavigation") stopNavigation: QueryList<ElementRef>;

    firstRun: boolean = true
    constructor(
        protected HttpDataService: HttpDataService,
        private toastr: PipedToasterService,
        private modalService: NgbModal,
        private router: Router,
        private ActivatedRoute: ActivatedRoute,
        private DataTableSharedService: DataTableSharedService
    ) {
        // this.DataTableSharedService.navigateEvent.subscribe(event => this.navigate(
        //     event.id,
        //     event.event,
        //     event.updateModal,
        //     event.forceNavigation
        // ))

    }

    ngOnInit(): void {

    }

    waitUntilDataIsAvailable(param){
        if( this.isDataAvailable ){
            this.navigate(param.id, undefined, this.updateModal, true)
        }else{
            setTimeout(()=>{this.waitUntilDataIsAvailable(param)},10)
        }
    }

    isDataAvailable: boolean = false
    ngOnChanges(change){
        if(change?.serverResp?.currentValue?.dashboardOptions?.permPerfix){
            this.serverResp = change?.serverResp?.currentValue
            this.isDataAvailable = true
        }
    }

    ngAfterViewInit() {
        this.ActivatedRoute.queryParams.subscribe(param => {
            this.firstRun && param.id ? (this.waitUntilDataIsAvailable(param),this.firstRun = false) : ""
        })
        if(!this.serverResp.system) this.serverResp.system = {}
        this.serverResp.system.type = 'update'
        this.serverResp.system.targetModel = 'updateModel'
    }

    /**
     * Check If User is Allowed to Update Entry
     */
    checkUpdatePermission() {
        
        if( this.serverResp?.dashboardOptions?.update != null && this.serverResp?.dashboardOptions?.update != undefined ){
            if( this.serverResp?.dashboardOptions?.update == false ) return false
        }
        if( this.serverResp?.dashboardOptions?.edit != null && this.serverResp?.dashboardOptions?.edit != undefined ){
            if( this.serverResp?.dashboardOptions?.edit == false ) return false
        }

        if (
            !this.HttpDataService.hasPermissionDirect([`${this.serverResp?.dashboardOptions?.permPerfix}_update`])
        ) {
            return false
        }

        return true
    }

    /**
     * Check If Clicked Element is in disabled areas
     */
    checkClickEvent(event: Event) {
        let navigate = true

        let clickedElem = event.target as HTMLElement;

        this.stopNavigation.forEach((elem: any) => {
            // let stopNavigationElem = elem?.nativeElement as HTMLElement;

            // if (stopNavigationElem?.contains(clickedElem)) {
            //     navigate = false;
            // }

            this.dataTable.nativeElement.querySelectorAll(".stopNav").forEach(elem => {
                elem = elem as HTMLElement
                elem.contains(clickedElem) ? navigate = false : ""
            })
        });

        console.log(navigate)
        return navigate
    }

    openNewTab(url: string){
        let anchor = document.createElement("a")
        anchor.target = "_blank"
        anchor.href = url
        document.querySelector("body").appendChild(anchor)
        anchor.click()
        anchor.remove()
    }

    /**
     * Opens Edit Record Modal
     * If User has Permission
     */
    async navigate(id: number, event: Event, updateModal: any, forceNavigation: boolean = false) {
        let clickUrl = this.serverResp?.dashboardOptions?.clickUrl
        let editRoute = this.serverResp?.dashboardOptions?.editRoute

        if(!this.serverResp.system) this.serverResp.system = {}
        this.serverResp.system.type = 'update'
        this.serverResp.system.targetModel = 'updateModel'

        if (!this.checkUpdatePermission() && !clickUrl) return

        if (event) {
            let navigate = this.checkClickEvent(event)

            if (!navigate && !forceNavigation) return
        }

        if(clickUrl){
            return this.openNewTab(clickUrl.replace("${id}", id))
        }

        if(editRoute){
            return this.router.navigate([`${editRoute}/${id}`])
        }

        this.serverResp.dashboardOptions.updateId = id;

        this.modalService.open(this.updateModal, { centered: true }).dismissed.subscribe(x=>{
            let state = window.location.href.split("?")[1]
            let newState = ""
            if (state) {
              state.split("&").forEach(splittedState=>{
                splittedState.split("=")[0] != "id" ? newState += splittedState : ""
              })
              if(newState != ""){
                newState = window.location.pathname + "?" + newState
              }else{
                newState = window.location.pathname
              }
            }
            window.history.replaceState({}, '', newState);
        })

        // this.router.navigate([], {
        //     queryParams: { id: id },
        //     queryParamsHandling: 'merge',
        //     skipLocationChange: false,
        //     replaceUrl: true,
        // });
        let state = window.location.href.split("?")[1]
        let newState
        if(state){
            if(state.indexOf("id") > -1) return
            newState = window.location.pathname + "?" + state + "&id=" + id
        }else{
            newState = window.location.pathname + "?id=" + id
        }
        window.history.replaceState({}, '', newState);


    }

    /**
     * Sends Updated Modal To Parent
     */
    savedFilterData: any
    savedModel: any
    fetchData(page: number = 1, filterData: any = {}, modal = undefined) {
        this.savedFilterData = filterData
        this.savedModel = modal
        this.fetchDataOutput.emit({
            page, filterData, modal
        })
    }

}