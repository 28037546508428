export class RequiredValidation {
  parent: any
  constructor(parent) { this.parent = parent }
  validate(value: any, key: string, data?: any) {

    if (!data.required) return

    if (
      value === undefined ||
      value === null ||
      (typeof value == 'string' && value?.trim() === "") ||
      (Array.isArray(value) && value?.length == 0)
    ) {
      this.parent.ErrorValidation.setError(key, "This field is required");
    }
  }
}