import { NgModule } from "@angular/core";
import { Router, RouterModule, Routes } from "@angular/router";
import { NgxChartComponent } from "./ngx-chart.component";

const routes: Routes = [
  {
    path: "ngx-chart-module",
    component: NgxChartComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxChartRoutingModule {}
