import { Component, PLATFORM_ID, Inject } from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
// import { TranslateService } from '@ngx-translate/core';
import { HttpDataService } from './components/ijd-services/httpService.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private HttpDataService: HttpDataService,
    private router: Router,
    private ActivatedRoute: ActivatedRoute,
  ) {
    // if (isPlatformBrowser(this.platformId)) {
    //   translate.setDefaultLang('en');
    //   translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    // }
    this.tokenCheck()
  }

  goToLogin() {
    this.router.navigateByUrl(`/auth/login?redirectTo=${encodeURIComponent(window.location.href)}`);
  }

  async tokenCheck() {
    let token = this.HttpDataService.getUserToken()
    if (token) {
      //check if token is still valid
      let tokenCheck = await this.HttpDataService.get("auth/admin/tokenCheck", { auth: true, handleError: false })
      if (tokenCheck.success) {
        this.HttpDataService.logUserIn(tokenCheck)
      } else {
        this.goToLogin()
      }
    } else {
      this.goToLogin()
    }
  }
}

